import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "stores";

import { Alert, Loader, Topbar, EmptyComponent } from "components";

import { FetchStatus } from "types";
import { isEmpty } from "utils";

export const Event: FC = () => {
  const navigate = useNavigate();

  const { fetchAllEvent, setError } = useStoreActions((action) => action.event);
  const { events, status, error } = useStoreState((state) => state.event);
  const { client_code } = useStoreState((state) => state.membership);

  const getEvent = useCallback(() => {
    fetchAllEvent({
      page: 1,
      limit: 100,
      client_code,
      status: "running",
    });
  }, [fetchAllEvent]);

  useEffect(() => {
    getEvent();
  }, []);

  return (
    <div className="event-container">
      <Topbar title="Event" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : events.length > 0 ? (
        <div className="content">
          {events.map((item, index) => (
            <div
              className="card"
              key={index}
              onClick={() => navigate(`/event/${item.id}`)}
            >
              <img className="image" src={item.photos[0]} alt="promo-banner" />
              <div className="detail">
                <div className="title">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyComponent title="Empty" message="No Event" />
      )}
      {!isEmpty(error) && (
        <Alert title="Error" onClick={() => setError("")} message={error} />
      )}
    </div>
  );
};
