import { api } from 'utils'


const deleteMembership = async (id: number) => {
  try {
    const res = await api.delete(`customer/inactive-membership/${id}`)
    const { data } = res
    return data
  } catch (e) {
    throw e
  }
}

export const MembershipService = {
  deleteMembership
}
