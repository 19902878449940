import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { api, retailApi } from "utils";

type Level = {
  barcode: string;
  card_type_id: number;
  customer_id: number;
  level_name: string;
  target_amount: number;
};

export type MemberLevel = {
  current_level: Level[];
  next_level: Level[];
  past_level: Level[];
  total_expense: number;
};

export const useMemberLevel = (
  clientCode: string,
  accessToken: string,
  barcode: string,
  clientType: string
) => {
  const [data, setData] = useState<MemberLevel>({} as MemberLevel);

  const fetchProfile = useCallback(async () => {
    try {
      const url = `/customer/level?code_client=${clientCode}&access_token=${accessToken}&barcode=${barcode}`;
      const res = await (clientType === "retail" ? retailApi : api).get(url);
      const { data } = res.data;
      setData(data);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  }, [clientCode, accessToken]);

  useEffect(() => {
    if (clientCode && accessToken && clientType !== "retail") fetchProfile();
  }, [clientCode, accessToken, clientType, fetchProfile]);

  return data;
};
