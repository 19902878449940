import { FC } from "react";

interface EmptyComponentProps {
  title: string;
  message: string;
}

export const EmptyComponent: FC<EmptyComponentProps> = ({ title, message }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        opacity: 0,
        animation: "show-card 1s ease forwards",
      }}
    >
      <h2 style={{ marginBottom: "15px", textAlign: "center", color: "#333" }}>
        {title}
      </h2>
      <p style={{ fontSize: "16px", color: "#555", textAlign: "center" }}>
        {message}
      </p>
    </div>
  );
};
