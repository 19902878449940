import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/scrollbar'
import { useStoreActions, useStoreState } from 'stores'
import { BottomSheet, Button, Loader, Topbar } from 'components'
import { FetchStatus } from 'types'
import { QRFunc } from 'react-qrbtf'

export const Profile: FC = () => {
  const navigate = useNavigate()

  const [showCode, setShowCode] = useState<boolean>(false)

  const { isAuthenticated } = useStoreState((state) => state.auth)
  const { loggedOut } = useStoreActions((action) => action.auth)
  const { fetchProfile } = useStoreActions(action => action.profile)
  const { profile, status: statusProfile } = useStoreState(state => state.profile)

  useEffect(() => {
    if (isAuthenticated) fetchProfile()
  }, [isAuthenticated, fetchProfile])

  useEffect(() => {
    fetchProfile()
  }, [])

  const loading = statusProfile === FetchStatus.LOADING

  return (
    <div className="account-container">
      {loading && <Loader />}
      <Topbar
        title="Account"
        onBack={() => navigate(-1)}
      />
      <div className="header">
        <div className="profile-picture-box">
          {profile?.profile_picture ?
            <img
              className="profile-picture"
              src={profile?.profile_picture}
              alt="profile-picture"
            />
            :
            <img
              className="profile-picture-placeholder"
              src={require('assets/icons/account.png')}
              alt="profile-picture"
            />
          }
        </div>
        <div className="phone-number">{profile.name}</div>
        <div className="phone-number">+{profile.phone.mobile}</div>
        <img
          className="qr-code"
          src={require('assets/icons/qr.png')}
          alt="icon"
          onClick={() => setShowCode(true)}
        />
      </div>
      <div
        className="settings-card"
      >
        <div className="title">
          Settings
        </div>
        <div className="menu">
          <div
            className="card"
            onClick={() => navigate('/profile/edit')}
          >
            <div className="label">Profile</div>
            <img
              className="icon"
              src={require('assets/icons/chevron-right.png')}
            />
          </div>
          <div
            className="card"
            onClick={() => navigate('/change-password')}
          >
            <div className="label">Change Password</div>
            <img
              className="icon"
              src={require('assets/icons/chevron-right.png')}
            />
          </div>
        </div>
      </div>
      <div className="bottom-action">
        <Button
          label="Logout"
          onClick={() => loggedOut(() => console.log('Logged Out'))}
        />
      </div>
      <BottomSheet
        isOpen={showCode}
        onChange={setShowCode}
      >
        <div className="voucher-profile">
          <div className="title">Loyalty Code</div>
          <div className="info">Show this code to be scanned by the staff</div>
          <div className="qr-box">
            <QRFunc
              className="qr-code"
              value={profile.barcode}
              type="round"
              posType="round"
            />
          </div>
          <div className="code">{profile.barcode}</div>
        </div>
      </BottomSheet>
    </div>
  )
}
