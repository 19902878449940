import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FC, useEffect } from "react";

interface Props {
  label: string;
  date: Date | null;
  setDate: (date: Date | null) => void;
  setDateStr: (value: string) => void;
}

export const InputDate: FC<Props> = ({ label, date, setDate, setDateStr }) => {
  useEffect(() => {
    if (date) {
      setDateStr(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label style={{ margin: 0 }}>{label}</label>
      <DatePicker
        selected={date}
        onChange={(date) => setDate(date as Date)}
        dateFormat="dd-MM-yyyy"
        placeholderText="DD-MM-YYYY"
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};
