import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Alert, Button, Input, Loader, Topbar } from "components";

import { isEmpty } from "utils";
import { useConvertPoint } from "./hooks";
import { FormStatus } from "types";

export const ConvertPoint: FC = () => {
  const navigate = useNavigate();

  const [point, setPoint] = useState<number>(1);
  const [luckyNumber, setLuckyNumber] = useState<number>(1);
  const { convertInquiry, convert, error, status } =
    useConvertPoint(luckyNumber);

  useEffect(() => {
    if (convertInquiry) {
      const convertPoint = point / convertInquiry?.redeem_point;
      setLuckyNumber(Number.isInteger(convertPoint) ? convertPoint : 0);
    }
  }, [point, convertInquiry]);

  return (
    <div className="convert-point-container">
      <Topbar title="Convert Point" onBack={() => navigate(-1)} />
      {/* {status === FetchStatus.LOADING ?
        <Loader />
        :
        <div className="content">
          {events.map((item, index) =>
            <div className="card" key={index}>
              <img className="image" src={item.photos[0]} alt="promo-banner" />
              <div className="detail">
                <div className="title">{item.name}</div>
              </div>
            </div>
          )}
        </div>
      } */}
      <div className="content">
        <div
          style={{
            fontSize: ".8rem",
          }}
        >
          Convert your rewards easily with one click
        </div>
        <div
          style={{
            fontWeight: 600,
            marginTop: "1rem",
          }}
        >
          Conversion Rate
        </div>
        <div
          style={{
            fontSize: ".8rem",
            fontWeight: 500,
            marginTop: ".3rem",
          }}
        >
          {convertInquiry?.redeem_point || 1} Pt ={" "}
          {convertInquiry?.lucky_draw || 1} Draw
        </div>
        <div className="convert-card">
          <div className="top">
            <div className="name">Convert Point</div>
          </div>
          <div className="detail">
            <div className="card">
              <div
                style={{
                  fontSize: ".8rem",
                }}
              >
                From <span style={{ fontWeight: 600 }}>Redeem Point</span>
              </div>
              <Input
                name="redeem_point"
                value={point}
                onChange={(e) =>
                  setPoint(Number(e.target.value.replace(/[^0-9]/g, "")))
                }
              />
              <div
                style={{
                  fontSize: ".8rem",
                }}
              >
                To <span style={{ fontWeight: 600 }}>Lucky Number</span>
              </div>
              <Input name="redeem_point" value={luckyNumber} disabled />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: "1rem",
          }}
        >
          <Button label="Convert" onClick={convert} />
        </div>
      </div>
      {!isEmpty(error) && (
        <Alert
          title="Convert Point"
          message={error}
          onClick={() => navigate(-1)}
        />
      )}
      {status === FormStatus.SUCCESS && (
        <Alert
          message="Convert Success"
          title="Convert Point"
          onClick={() => navigate(-1)}
        />
      )}
    </div>
  );
};
