import {
  api,
  getClientCode,
  getMemberAccessToken,
  isRetailClient,
  retailApi,
} from "utils";
import { CLIENT_CODE } from "constant";

import { InternalRedeemDto, UltraVoucherRedeemDto, ExternalRedeemDto } from ".";

const submitBookingRedeem = async (
  dto: InternalRedeemDto | UltraVoucherRedeemDto | ExternalRedeemDto
): Promise<any> => {
  const access_token = await getMemberAccessToken();
  const codeMerchant = await getClientCode();
  const isRetail = await isRetailClient();

  try {
    const body = {
      client_code: isRetail ? codeMerchant : CLIENT_CODE,
      access_token,
      ...dto,
    };
    let URL =
      dto.type.toLocaleLowerCase() === "voucher"
        ? "redeem/voucher"
        : "redeem/book";
    if (dto.source.toLowerCase() === "ultra voucher") URL = "uv/open-orders";
    if (dto.source.toLowerCase() === "external") URL = "redeem/external";

    const res = await (isRetail ? retailApi : api).post(URL, body);
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

export const bookingRedeemService = {
  submitBookingRedeem,
};
