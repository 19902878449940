import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStoreActions, useStoreState } from 'stores'
import { AuthStatus } from 'types'

export const SplashScreen = () => {
  const navigate = useNavigate()

  const { authStatus, isAuthenticated } = useStoreState((state) => state.auth)
  const { checkAuth } = useStoreActions((action) => action.auth)

  useEffect(() => {
    checkAuth()
  }, [checkAuth])

  console.log('AUTH_STATUS', authStatus, 'IS_AUTHENTICATED', isAuthenticated)

  useEffect(() => {
    setTimeout(() => {
      if (!isAuthenticated && authStatus !== AuthStatus.LOADING)
        navigate('/login')
      else
        navigate('/membership')
    }, 2000)
  }, [authStatus, isAuthenticated, navigate])

  return (
    <div className="splash-screen">
      <div className="logo">
        <img
          src={require('assets/logo/loyalid.svg').default}
          alt="logo"
        />
      </div>
    </div>
  )
}
