import { useState } from "react";
import { AxiosError } from "axios";

import { api, persistMemberAccessToken, retailApi } from "utils";
import { useStoreActions } from "stores";

type Profile = {
  active: boolean;
  address: {
    address: string;
    city: number;
    country: number;
    default: boolean;
    id: number;
  };
  api_token: string;
  area: number;
  barcode: string;
  card_type: {
    active: boolean;
    default: boolean;
    expired_period: number;
    id: number;
    image: string;
  };
  code: string;
  id: number;
  id_type: {
    code: number;
    id: number;
    name: string;
  };
  interest: string[];
  name: string;
  nationality: string;
  occupation: string;
  point: {
    lucky_draw: number;
    redeem: number;
  };
  profile_picture: string;
  socials: string[];
  total_expence: number;
};

type UseBarcodeLogin = {
  data: Profile;
  submit: (
    barcode: string,
    clientCode: string,
    clientType: string,
    phone?: string
  ) => void;
};

export const useLoginBarcode = (): UseBarcodeLogin => {
  const [data, setData] = useState<Profile>({} as Profile);
  const { loggedInMember } = useStoreActions((action) => action.auth);

  const submit = async (
    barcode: string,
    clientCode: string,
    clientType: string,
    phone?: string
  ) => {
    try {
      const formData = new FormData();
      formData.append(
        clientType === "retail" ? "password" : "barcode",
        barcode
      );
      formData.append(
        clientType === "retail" ? "client_code" : "code_client",
        clientCode
      );
      formData.append("phone", phone ? phone : "");
      const res =
        clientType === "retail"
          ? await retailApi.post("/login", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
          : await api.post("/customer/login-barcode", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
      const { data } = res.data;
      loggedInMember(data.api_token);
      setData(data);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  };

  return { data, submit };
};
