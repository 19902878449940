import { useFormik } from "formik";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useChangePassword } from "./hooks";
import { Alert, Button, Input, Loader, Topbar } from "components";
import { isEmpty } from "utils";
import { FormStatus } from "types";

export const ChangePassword: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");

  const PasswordSchema = yup.object().shape({
    passwordOld: yup.string().required("Required"),
    password: yup
      .string()
      .min(8, "Password too short")
      .max(20, "Password too long")
      .required("Required"),
    passwordConfirmation: yup
      .string()
      .required("Required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  });

  const [isLoading, submit, status] = useChangePassword(setError);

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      passwordOld: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: PasswordSchema,
    onSubmit: (values) => submit(values),
  });

  return (
    <div className="change-password">
      {isLoading && <Loader />}
      <Topbar onBack={() => navigate(-1)} title="Change Password" />
      <form onSubmit={handleSubmit} className="form">
        <Input.Password
          name="passwordOld"
          onChange={handleChange}
          value={values.passwordOld}
          placeholder="Old Password"
        />
        {touched.passwordOld && errors.passwordOld && (
          <div className="error-message">*{errors.passwordOld}</div>
        )}
        <Input.Password
          name="password"
          onChange={handleChange}
          value={values.password}
          placeholder="New Password (8+ Characters)"
        />
        {touched.password && errors.password && (
          <div className="error-message">*{errors.password}</div>
        )}

        <Input.Password
          name="passwordConfirmation"
          onChange={handleChange}
          value={values.passwordConfirmation}
          placeholder="Confirm Password"
        />
        {touched.passwordConfirmation && errors.passwordConfirmation && (
          <div className="error-message">*{errors.passwordConfirmation}</div>
        )}
        <Button type="submit" label="Submit" />
      </form>
      {!isEmpty(error) && (
        <Alert title="Error" message={error} onClick={() => setError("")} />
      )}
      {status === FormStatus.SUCCESS && (
        <Alert
          title="Success"
          message={"Your Password has been changed"}
          onClick={() => navigate(-1)}
        />
      )}
    </div>
  );
};
