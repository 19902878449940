import { FC, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export interface Options {
  name: string;
  id: number;
}

export interface Selected {
  value: number;
  label: string;
}

export interface DropdownProps {
  options: Options[];
  onSelectChange: (value: Selected) => void;
  label: string;
  isMulti?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  options,
  isMulti = false,
  label,
  onSelectChange,
}) => {
  const animatedComponents = makeAnimated();

  const modifiedOptions = options?.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));

  const handleSelect = (data: Selected) => {
    onSelectChange(data);
  };

  return (
    <div style={{ padding: ".3rem 0" }}>
      <label style={{ display: "block", margin: ".5rem 0" }}>{label}</label>
      <Select
        options={modifiedOptions}
        components={animatedComponents}
        isMulti={isMulti}
        onChange={(data) => handleSelect(data as Selected)}
        isDisabled={options?.length === 0 || !options}
      />
    </div>
  );
};
