import { Alert, Button, Input, Topbar } from "components";
import { FC, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import "./styles.scss";
import { useRegisterNew, useSendVerificationCode } from "pages/register/hooks";
import { FormStatus } from "types";
import { isEmpty } from "utils";
import { useStoreActions, useStoreState } from "stores";

export const RegisterOTP: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, phone, password } = location.state;
  const [token, setToken] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(60);
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile } = useStoreState((state) => state.profile);

  const sendEmailVerificationCode = useSendVerificationCode();
  const registerNew = useRegisterNew(setToken);

  const [otp, setOtp] = useState<string>("");

  const handleChange = (value: string) => {
    setOtp(value);
  };

  const sendCode = () => {
    sendEmailVerificationCode.submit(phone);
    setTimeLeft(60);
  };

  const handleSubmit = () => {
    register(otp);
  };

  useEffect(() => {
    if (timeLeft === 0) setTimeLeft(0);
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    sendCode();
  }, []);

  const register = (code: string) => {
    registerNew.submit({
      name,
      mobile_phone: phone,
      password,
      phone_verification_code: code,
    });
  };

  useEffect(() => {
    if (sendEmailVerificationCode.status === FormStatus.SUCCESS) {
      // otp send
    }

    if (sendEmailVerificationCode.status === FormStatus.ERROR) {
      console.log("OTP Failed");
    }
  }, [sendEmailVerificationCode.status, sendEmailVerificationCode.error]);

  const handleError = () => {
    sendEmailVerificationCode.setError("");
    registerNew.setError("");
  };

  useEffect(() => {
    if (isAuthenticated) fetchProfile();
  }, [isAuthenticated, fetchProfile]);

  useEffect(() => {
    if (!isEmpty(profile) && isAuthenticated)
      navigate("/home", { replace: true });
  }, [profile, navigate]);

  return (
    <div className="register-otp">
      <Topbar title="Register" onBack={() => navigate(-1)} />
        <span className="text">Please enter the OTP code we sent to </span>
        <span className="text">+{phone}</span>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={4}
        renderSeparator={<span></span>}
        renderInput={(props) => <input {...props} />}
        containerStyle={{ justifyContent: "center" }}
        inputStyle={{
          width: "40px",
          height: "40px",
          margin: "10px",
          fontSize: "18px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          outline: "none",
          textAlign: "center",
        }}
      />
      <div className="resend">
        {timeLeft === 0 ? (
          <div onClick={() => sendCode()}>
            <text>Resend a new code</text>
          </div>
        ) : (
          <text>
            Having trouble? Request a new code in 00:
            {timeLeft < 10 ? `0${timeLeft}` : timeLeft}
          </text>
        )}
      </div>
      <Button label="Submit" onClick={handleSubmit} disabled={!otp} />
      {!isEmpty(sendEmailVerificationCode.error || registerNew.error) && (
        <Alert
          title="Error"
          message={sendEmailVerificationCode.error || registerNew.error}
          onClick={handleError}
        />
      )}
    </div>
  );
};
