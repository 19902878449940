import { createStore, createTypedHooks, EasyPeasyConfig, persist } from 'easy-peasy'

import { AuthModel, auth } from './auth'
import { ProfileModel, profile } from './profile'
import { PromoModel, promo } from './promo'
import { RedeemProductModel, redeemProduct } from './redeem_product'
import { RedeemVoucherModel, redeemVoucher } from './redeem_voucher'
import { TransactionModel, transaction } from './transaction'
import { MembershipModel, membership } from './membership'
import { EventModel, event } from './event'
import { OutletModel, outlet } from './outlet'

export * from './auth'
export * from './profile'
export * from './promo'
export * from './redeem_product'
export * from './redeem_voucher'
export * from './transaction'
export * from './membership'
export * from './event'
export * from './outlet'

export interface StoreModel {
  auth: AuthModel,
  profile: ProfileModel,
  promo: PromoModel,
  redeemProduct: RedeemProductModel,
  redeemVoucher: RedeemVoucherModel,
  transaction: TransactionModel,
  outlet: OutletModel,
  membership: MembershipModel,
  event: EventModel
}

const storeModel: StoreModel = {
  auth,
  profile,
  promo,
  redeemProduct,
  redeemVoucher,
  transaction,
  outlet,
  membership,
  event
}

const storeConfig: EasyPeasyConfig = {
  devTools: true
}

const store = createStore(persist(storeModel), storeConfig)
const typedHooks = createTypedHooks<StoreModel>()

export const { useStoreActions, useStoreDispatch, useStoreState } = typedHooks
export default store
