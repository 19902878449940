import { useMemberships } from "pages/membership/hooks";
import { Client, useGetMerchant } from "./hooks";
import { Loader, Topbar } from "components";
import { useNavigate } from "react-router-dom";
import { Images } from "assets";
import { clearMerchant, perssitClientCode, setClientType } from "utils";
import { useEffect, useState } from "react";

export const AddCard = () => {
  const navigate = useNavigate();
  const { data: membership, isLoading } = useMemberships();
  const { data: merchant } = useGetMerchant();
  const [cleaned, setCleaned] = useState<boolean>(false);

  const isAdded = (item: Client) =>
    membership.find((obj) => {
      return obj.client.code === item.clientCode;
    });

  const addMerchant = (item: Client) => {
    if (item.clientType) {
      setClientType(item.clientType);
    } else {
      setClientType("PORTAL");
    }
    perssitClientCode(item.clientCode);
    navigate("/scanqr", { state: { item } });
  };

  useEffect(() => {
    const cleanup = async () => {
      await clearMerchant();
      setCleaned(true);
    };
    cleanup();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="add-card">
          <Topbar title="Add Card" onBack={() => navigate(-1)} />
          <div className="content">
            {merchant.map((item, index) => (
              <div
                key={`${item.name}+${index}`}
                className="card-container"
                style={{
                  animation: `show-card .5s ${index * 0.1}s ease forwards`,
                }}
              >
                <div className="merchant">
                  <img
                    src={item?.image ? item.image : Images.loyalid}
                    className="icon"
                  />
                  <span>{item.name}</span>
                </div>
                {!isAdded(item) ? (
                  <span
                    className="add"
                    onClick={() => addMerchant(item)}
                    style={{ cursor: "pointer" }}
                  >
                    +Add
                  </span>
                ) : (
                  <span className="add" style={{ opacity: 0.3 }}>
                    Added
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
