import { Alert, Button, Input, Topbar } from "components";
import { FC, useState, FormEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { useValidateRegisterNew } from "pages/register/hooks";
import { isEmpty } from "utils";
import { FormStatus } from "types";

export const RegisterPassword: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { name, phone } = location.state;

  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (password === confirmPassword) {
      validate.submit({ name, mobile_phone: phone, password });
    } else {
      setError(true);
    }
  };

  const validate = useValidateRegisterNew();

  useEffect(() => {
    if (validate.status === FormStatus.SUCCESS) {
      navigate("/register/OTP", {
        state: {
          name,
          phone,
          password,
        },
      });
    }
  }, [validate.status]);

  return (
    <div className="register-name">
      <Topbar title="Register" onBack={() => navigate(-1)} />
      <div className="input-password">
        <label>Password</label>
        <Input.Password
          name="password"
          placeholder="Enter your password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
      </div>
      <div className="input-confirm">
        <label>Confirm Password</label>
        <Input.Password
          name="confirmPassword"
          placeholder="Confirm your password"
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
        />
      </div>
      <Button
        label="Continue"
        onClick={handleSubmit}
        disabled={!password || !confirmPassword}
      />
      {error && (
        <Alert
          title="Error"
          message="Passwords do not match"
          onClick={() => setError(false)}
        />
      )}
      {!isEmpty(validate.error) && (
        <Alert
          title="Error"
          message={validate.error}
          onClick={() => validate.setError("")}
        />
      )}
    </div>
  );
};
