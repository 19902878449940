import { useCallback, useEffect, useState } from "react";
import { api, getClientType, isRetailClient, retailApi } from "utils";

export interface UseMerchanData {
  country: any[];
  area: any[];
  typeID: any[];
  interest: any[];
  required: any[];
  province: any[];
  city: any[];
  loading: boolean;
  fetchCity: (dto: any) => Promise<void>;
}

export const useMerchantData = (clientCode: string): UseMerchanData => {
  const [country, setCountry] = useState<any[]>([]);
  const [typeID, setTypeId] = useState<any[]>([]);
  const [area, setArea] = useState<any[]>([]);
  const [interest, setInterest] = useState<any[]>([]);
  const [required, setRequired] = useState<any[]>([]);
  const [province, setProvince] = useState<any[]>([]);
  const [city, setCity] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchCountry = useCallback(async () => {
    try {
      const url = `/master/country`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setCountry(data);
      fetchProvince(data[0].id);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchProvince = useCallback(async (id: string) => {
    try {
      const url = `/master/country/${id}/province`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setProvince(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchCity = useCallback(async (id: string) => {
    try {
      const isRetail = await isRetailClient();
      const url = `/master/province/${id}/city`;
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setCity(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchTypeId = useCallback(async (clientCode: string) => {
    try {
      const url = `/master/id-type?client_code=${clientCode}`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setTypeId(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchArea = useCallback(async (clientCode: string) => {
    try {
      const url = `/master/area?client_code=${clientCode}`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setArea(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchInterest = useCallback(async (clientCode: string) => {
    try {
      const url = `/master/interest?client_code=${clientCode}`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setInterest(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchRequiredInquiry = useCallback(async (clientCode: string) => {
    try {
      const url = `/customer/required-inquiry?code_client=${clientCode}`;
      const isRetail = await isRetailClient();
      const res = await (isRetail ? retailApi : api).get(url);
      const { data } = res.data;
      setRequired(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchCountry(),
          fetchTypeId(clientCode),
          fetchArea(clientCode),
          fetchInterest(clientCode),
          fetchRequiredInquiry(clientCode),
        ]);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [clientCode]);

  return {
    country,
    area,
    typeID,
    interest,
    required,
    province,
    city,
    fetchCity,
    loading,
  };
};
