import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useNavigate } from "react-router-dom";

import { Membership } from "types";
import { useStoreActions, useStoreState } from "stores";
import { formatCurrency, perssitClientCode, setClientType } from "utils";
import { Alert } from "components";

interface Props {
  data: Membership;
  index: number;
  openCode: (code: string) => void;
  setDeleteAlert: Dispatch<SetStateAction<boolean>>;
  setMerchantId: Dispatch<SetStateAction<number>>;
}

export const Card: FC<Props> = ({
  data,
  index,
  openCode,
  setDeleteAlert,
  setMerchantId,
}) => {
  const navigate = useNavigate();
  const { setMembership, setClientCode } = useStoreActions(
    (action) => action.membership
  );

  const [count, setCount] = useState<number>(1);
  const [count2, setCount2] = useState<number>(1);
  const [scrolling, setScrolling] = useState<boolean>(false);
  const [touch, setTouch] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  let timerID: any;
  let counter = 0;

  let pressHoldEvent = new CustomEvent("pressHold");

  let pressHoldDuration = 20;

  const timer = useCallback(() => {
    if (counter > -pressHoldDuration) {
      timerID = requestAnimationFrame(timer);
      counter--;
      setCount(1 + counter / 500);
      setCount2(1 + counter / 20);
    } else {
      ref.current?.dispatchEvent(pressHoldEvent);
    }
  }, []);

  const pressingDown = useCallback(
    (e: any) => {
      setTouch(true);
      timer();
      setTimeout(() => {
        e.preventDefault();
      }, 1);
    },
    [timer]
  );

  const notPressingDown = useCallback((e: any) => {
    setTouch(false);
    setScrolling(false);
    cancelAnimationFrame(timerID);
    counter = 0;
    setCount(1);
    setCount2(1);
  }, []);

  const doSomething = useCallback(() => {
    // if (!scrolling) navigate('/client')
  }, [scrolling]);

  const scroll = useCallback((e: any) => {
    setScrolling(true);
  }, []);

  useEffect(() => {
    ref.current?.addEventListener("mousedown", pressingDown, false);
    ref.current?.addEventListener("touchstart", pressingDown, false);
    return () => {
      ref.current?.removeEventListener("mousedown", pressingDown, false);
      ref.current?.removeEventListener("touchstart", pressingDown, false);
    };
  }, [pressingDown]);

  useEffect(() => {
    ref.current?.addEventListener("mouseup", notPressingDown, false);
    ref.current?.addEventListener("mouseleave", notPressingDown, false);
    ref.current?.addEventListener("touchend", notPressingDown, false);
    return () => {
      ref.current?.removeEventListener("mouseup", notPressingDown, false);
      ref.current?.removeEventListener("mouseleave", notPressingDown, false);
      ref.current?.removeEventListener("touchend", notPressingDown, false);
    };
  }, [notPressingDown]);

  // useEffect(() => {
  //   ref.current?.addEventListener('pressHold', doSomething, false)
  //   return () => {
  //     ref.current?.removeEventListener('pressHold', doSomething, false)
  //   }
  // }, [doSomething])

  useEffect(() => {
    ref.current?.addEventListener("mouseup", doSomething, false);
    ref.current?.addEventListener("touchend", doSomething, false);
    return () => {
      ref.current?.removeEventListener("mouseup", doSomething, false);
      ref.current?.removeEventListener("touchend", doSomething, false);
    };
  }, [doSomething]);

  useEffect(() => {
    ref.current?.addEventListener("touchmove", scroll, false);
    return () => {
      ref.current?.removeEventListener("touchmove", scroll, false);
    };
  }, [scroll]);

  // ref.current!.style.transform = "scale3d(" + scale + ", " + scale + ", 1)"

  const handleSelectMembership = (data: Membership) => {
    setMembership(data);
    setClientCode(data.client.code);
    perssitClientCode(data.client.code);
    setClientType(data.client_type);
    navigate("/home", { state: { data } });
  };

  return (
    <div
      style={{
        transform: `scale3d(${count}, ${count}, 1)`,
        transition: "transform cubic-bezier(0.175, 0.885, 0.32, 1.275) .2s",
      }}
    >
      <div
        ref={ref}
        key={index}
        style={{
          position: "relative",
          borderRadius: "1.5rem",
          // backgroundColor: '#f9f9f9',
          backgroundColor: "#2e4a8a",
          border: "1px solid #ddd",
          overflow: "hidden",
          opacity: 0,
          // boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          animation: `show-card 1s .${index}s ease forwards`,
        }}
        onClick={() => handleSelectMembership(data)}
      >
        <div
          style={{
            position: "absolute",
            top: "1rem",
            left: "1rem",
            height: 60,
            width: 60,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: "30%",
            backgroundImage: `url(${data.client.logo})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // border: '1px solid #eee',
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            opacity: 0,
            animation: `show-card-brand 1s .5s ease forwards`,
            zIndex: 2,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "1rem",
            right: ".8rem",
            height: 26,
            width: 26,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: '#eee',
            // backgroundColor: '#2e4a8a',
            borderRadius: "40%",
            opacity: count2,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setDeleteAlert(true);
            setMerchantId(data.id);
          }}
        >
          <img
            style={{
              height: 18,
              filter: "contrast(0) brightness(100)",
            }}
            src={require("assets/images/delete.png")}
            alt="icon"
          />
        </div>
        <div
          style={{
            backgroundColor: "white",
            padding: "2rem 1rem 1rem 1rem",
            marginTop: "0",
            animation: `show-card-top 1s .5s ease forwards`,
            borderTopLeftRadius: "1.4rem",
            borderTopRightRadius: "1.4rem",
          }}
        >
          <div
            style={{
              fontWeight: 800,
            }}
          >
            {data.client.name}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <img
                  src={require("assets/images/coins.png")}
                  style={{ height: 20 }}
                  alt="icon"
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      lineHeight: 0.8,
                      color: "#ff8d00",
                    }}
                  >
                    {formatCurrency(data.point.redeem || 0)}
                  </div>
                  <div style={{ fontSize: 10, color: "#999" }}>Points</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <img
                  src={require("assets/images/coupon.png")}
                  style={{ height: 20 }}
                  alt="icon"
                />
                <div>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      lineHeight: 0.8,
                      color: "#2d4a8a",
                    }}
                  >
                    {formatCurrency(data.point.lucky_draw || 0)}
                  </div>
                  <div style={{ fontSize: 10, color: "#999" }}>Lucky Draw</div>
                </div>
              </div>
            </div>
            <div
              style={{
                opacity: 0,
                animation: `show-card-brand .5s 1s ease forwards`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                openCode(data.barcode);
              }}
            >
              <img
                src={require("assets/images/qr-code.png")}
                style={{ height: 20 }}
                alt="icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
