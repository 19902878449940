import { Dispatch, SetStateAction, useState } from "react";
import { FormStatus } from "types";
import { api, getClientCode } from "utils";

export interface UseCreateMembership {
  status: FormStatus;
  submit: (dto: any) => Promise<void>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const useCreateMembership = (): UseCreateMembership => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);
  const [error, setError] = useState("");

  const submit = async (dto: any) => {
    try {
      const client_code = await getClientCode();
      const formData = new FormData();
      formData.append("code_client", client_code ?? "");
      for (const key in dto) {
        formData.append(key, dto[key]);
      }
      setError("");
      setStatus(FormStatus.LOADING);
      const res = await api.post("/customer/create-membership", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: (formData) => formData,
      });
      setStatus(FormStatus.SUCCESS);
    } catch (e: any) {
      setStatus(FormStatus.ERROR);
      setError(e?.response?.data?.message || "Network Error");
    }
  };

  return {
    status,
    submit,
    error,
    setError,
  };
};
