const portalURL = {
  dev: "https://portal-staging.loyal.id/api/v1/",
  prod: "https://portal-api-srv.loyal.id/api/v1/",
};

const retailURL = {
  dev: "https://retail-dev.loyal.id/api/v1/",
  prod: "https://connect-platform-api.loyal.id/api/v1/",
};

const production = true;

export const BASE_URL = portalURL[production ? "prod" : "dev"];
export const BASE_RETAIL = retailURL[production ? "prod" : "dev"];
export const HELPER_URL = "https://helperapi.loyal.id";

export const CLIENT_CODE = "0410101";
export const SECRET_CODE = "6LcMGjEUAAAAAH_TT2w_wfBCP9tYzuA3csPzp2wN";
export const PEM_KEY = process.env.REACT_APP_PEM_KEY!;

export const STORAGE_ACCESS_KEY = "ACCESS_TOKEN_KEY";
export const STORAGE_MEMBER_ACCESS_KEY = "ACCESS_MEMBER_TOKEN_KEY";
export const CLIENT_MEMBER_CODE = "CLIENT_MEMBER_CODE";
export const CLIENT_TYPE = "CLIENT_TYPE";
