export const Icons = {
  eyeOpen: require("./icons/eye-open.png"),
  addCard: require("./icons/add_card.png"),
  eyeClose: require("./icons/eye-close.png"),
  redeemPoint: require("./icons/redeem-point.png"),
  luckydraw: require("./icons/luckydraw.png"),
  arrowRight: require("./icons/arrow-right.png"),
  arrow: require("./icons/arrow.png"),
  back: require("./icons/back.png"),
  badge: require("./icons/badge.png"),
  calendar: require("./icons/calendar.png"),
  camera: require("./icons/camera.png"),
  checked: require("./icons/checked.png"),
  coin: require("./icons/coin.png"),
  down: require("./icons/down.png"),
  emoney: require("./icons/emoney.png"),
  gallery: require("./icons/gallery.png"),
  group: require("./icons/group.png"),
  game: require("./icons/game.png"),
  gift: require("./icons/gift.png"),
  history: require("./icons/history.png"),
  home: require("./icons/home.png"),
  id: require("./icons/id.png"),
  login: require("./icons/login.png"),
  jersey: require("./icons/jersey.png"),
  match: require("./icons/match.png"),
  more: require("./icons/more.png"),
  notification: require("./icons/notification.png"),
  passwordShow: require("./icons/password-show.png"),
  passwordHide: require("./icons/password-hide.png"),
  pennant: require("./icons/pennant.png"),
  polling: require("./icons/polling.png"),
  profile: require("./icons/profile.png"),
  qris: require("./icons/qris.png"),
  store: require("./icons/store.png"),
  team: require("./icons/team.png"),
  tick: require("./icons/tick.png"),
  ticket: require("./icons/ticket.png"),
  tv: require("./icons/tv.png"),
  up: require("./icons/up.png"),
  voucher: require("./icons/voucher.png"),
  wallet: require("./icons/wallet.png"),
  wifi: require("./icons/wifi.png"),
  catalogue: require("./icons/catalogue.png"),
  promotion: require("./icons/promotions.png"),
  instagram: require("./icons/instagram.png"),
  x: require("./icons/x.png"),
};

export const Images = {
  loyalid: require("./logo/logo.png"),
};
