import { Dispatch, SetStateAction, useState } from "react";
import { FormStatus } from "types";
import { api } from "utils";

export interface formDto {
  phone: string;
  number: string;
  clientCode: string;
  client_type: string
}

export interface UseAddMembership {
  status: FormStatus;
  submit: (dto: formDto) => Promise<void>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

export const useActivateMembership = (): UseAddMembership => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);
  const [error, setError] = useState("");

  const submit = async (dto: formDto) => {
    try {
      const formData = new FormData();
      formData.append("phone_number", `62${dto.phone}`);
      formData.append("client_type", dto?.client_type?.toLowerCase());
      formData.append("barcode", dto.number);
      formData.append("code_client", dto.clientCode);
      setError("");
      setStatus(FormStatus.LOADING);
      const res = await api.post("/customer/activate-membership", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: (formData) => formData,
      });
      console.log(res.data.data);
      setStatus(FormStatus.SUCCESS);
    } catch (e: any) {
      setStatus(FormStatus.ERROR);
      setError(e?.response?.data?.message || "Network Error");
    }
  };

  return {
    status,
    submit,
    error,
    setError,
  };
};
