import { api, isRetailClient, retailApi } from "utils";
import { PaginationData } from "types";

import {
  GetRedeemVouchersParams,
  RedeemVoucher,
  ValidateRedeemVoucherDto,
} from ".";
import { CLIENT_CODE } from "constant";

export const fetchRedeemVouchersUsed = async (
  params: GetRedeemVouchersParams
): Promise<PaginationData<RedeemVoucher>> => {
  try {
    const isRetail = await isRetailClient();

    const res = await (isRetail ? retailApi : api).get<
      PaginationData<RedeemVoucher>
    >("/customer/vouchers", {
      params: {
        access_token: params.access_token,
        code_client: params.code_client,
        limit: params.paginator.limit,
        page: params.paginator.page,
        status: params.status,
      },
    });
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

export const fetchRedeemVouchersUnused = async (
  params: GetRedeemVouchersParams
): Promise<PaginationData<RedeemVoucher>> => {
  try {
    const isRetail = await isRetailClient();

    const res = await (isRetail ? retailApi : api).get<
      PaginationData<RedeemVoucher>
    >("/customer/vouchers", {
      params: {
        access_token: params.access_token,
        code_client: params.code_client,
        limit: params.paginator.limit,
        page: params.paginator.page,
        status: params.status,
      },
    });
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

export const validateRedeemVoucher = async (
  dto: ValidateRedeemVoucherDto
): Promise<any> => {
  try {
    const body = {
      client_code: CLIENT_CODE,
      ...dto,
    };
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).post(
      "/redeem/voucher/validate",
      body
    );
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};
