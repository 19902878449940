import { useState } from 'react'
import { AxiosError } from 'axios'

import { api } from 'utils'
import { CLIENT_CODE } from 'constant'
import { useStoreActions } from 'stores'

export type Response = {
  status: string
  status_code: number
  message: string
  data: {
    id: number
    code: string
    barcode: string
    name: string
    id_number: string
    profile_picture: null | string
    active: boolean
    total_expense: number
    point: {
      redeem: number
      lucky_draw: number
    }
    api_token: string
    id_type: null | string
  }
}

type LoginDto = {
  phone: string
  password: string
}

export const useLogin = (
  onError: (message: string) => void,
): [boolean, (dto: LoginDto) => Promise<void>] => {
  const { loggedIn } = useStoreActions((action) => action.auth)
  const [isLoading, setIsLoading] = useState(false)

  const submit = async (dto: LoginDto) => {
    setIsLoading(true)
    try {
      const formData = new FormData()
      for (const key in dto) {
        // @ts-ignore
        formData.append(key, dto[key])
      }
      formData.append('client_code', CLIENT_CODE)
      const res = await api.post<Response>('/login',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        // transformRequest: formData => formData,
      })
      const { data } = res.data
      loggedIn(data.api_token)
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log('ERROR_LOGIN', e)
        setIsLoading(false)
        onError(e?.response?.data?.message || 'Network Error')
      } else {
        console.log('Unexpected error', e)
      }
    }
  }

  return [isLoading, submit]
}