import { useState, Dispatch, SetStateAction } from 'react'
import { KEYUTIL, KJUR } from "jsrsasign";
import { Buffer } from "buffer";
import { api } from 'utils'
import { FormStatus } from 'types'
import { CLIENT_CODE, PEM_KEY, SECRET_CODE } from 'constant'

type UseSendVerificationCode = {
  status: FormStatus
  submit: (phone: string) => Promise<void>
  error: string
  setError: Dispatch<SetStateAction<string>>
}

export const useSendVerificationCode = (): UseSendVerificationCode => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState('')

  const submit = async (phone: string) => {
    try {
      setError('')
      setStatus(FormStatus.LOADING)
      const smsToken = await getSmsToken()
      await sendVerificationCode(phone, smsToken)
      // await sendVerificationSignature(phone)
      setStatus(FormStatus.SUCCESS)
    } catch (e: any) {
      setStatus(FormStatus.ERROR) 
      setError(e?.response?.data?.message || 'Network Error')
    } finally {
      setStatus(FormStatus.IDLE)
    }
  }

  return {
    status,
    submit,
    error,
    setError
  }
}

const getSmsToken = async (): Promise<string> => {
  try {
    interface Response {
      status: string
      status_code: number
      data: {
        sms_token: string
      }
    }
    const formData = new FormData()
    formData.append('client_code', CLIENT_CODE)
    formData.append('secret_code', SECRET_CODE)
    const res = await api.post<Response>('/sms-token',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: formData => formData,
    })
    const { data } = res.data
    return data.sms_token
  } catch (e) {
    throw e
  }
}

const sendVerificationCode = async (phone: string, smsToken: string) => {
  try {
    const formData = new FormData()
    formData.append('mobile_phone', phone)
    formData.append('type', 'New Registration')
    formData.append('sms_token', smsToken)
    formData.append('client_code', CLIENT_CODE)
    await api.post('/verification/phone/send',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: formData => formData,
    })
  } catch (e) {
    throw e
  }
}

const sendVerificationSignature = async (phone: string) => {
  try {
    const formData = new FormData()
    formData.append('mobile_phone', phone)
    formData.append('type', 'New Registration')
    formData.append('client_code', CLIENT_CODE)
    const data ={mobile_phone : phone, type :'New Registration', client_code: CLIENT_CODE}
    const signature = generateSignatureJsr(JSON.stringify(data))
    await api.post('/verification-usig/phone/send',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'Signature': signature },
      transformRequest: formData => formData,
    })
  } catch (e) {
    throw e
  }
}

const generateSignatureJsr = (dataToSign: string) => {
  const rsaKey = KEYUTIL.getKey(PEM_KEY);
  const sig = new KJUR.crypto.Signature({ alg: "SHA256withRSA" });

  sig.init(rsaKey);
  sig.updateString(dataToSign);

  const signature = sig.sign();


  const binaryData = Buffer.from(signature, 'hex');

  const base64String = binaryData.toString('base64');

 return base64String
};