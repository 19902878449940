import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'

import stores from 'stores'

import { AuthProvider } from 'context/auth-context'
import { App } from 'components'

import reportWebVitals from './reportWebVitals'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Router>
    <AuthProvider>
      <StoreProvider store={stores}>
        <App />
      </StoreProvider>
    </AuthProvider>
  </Router>
)

reportWebVitals()