import { FC } from "react";

import "./styles.scss";

interface Props {
  title: string;
  message: string;
  onClick: () => void;
  onCancel?: () => void;
  deleting?: boolean;
}

export const Alert: FC<Props> = ({
  title,
  message,
  onClick,
  deleting,
  onCancel,
}) => {
  if (deleting) {
    return (
      <div className="alert-container">
        <div className="message-box">
          <div className="alert-title">{title}</div>
          <div className="alert-message">{message}</div>
          <div className="buttons">
            <div className="button-alert" onClick={onClick}>
              Yes
            </div>
            <div className="button-alert" onClick={onCancel}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="alert-container">
      <div className="message-box">
        <div className="alert-title">{title}</div>
        <div className="alert-message">{message}</div>
        <div className="button-alert" onClick={onClick}>
          OK
        </div>
      </div>
    </div>
  );
};
