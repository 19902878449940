import { useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { useForgot } from "./hooks";
import { isEmpty } from "utils";
import { Alert, Input, Topbar } from "components";
import { FormStatus } from "types";

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [error, setError] = useState<string>("");
  const { status, submit, isLoading, message } = useForgot(setError);

  const ForgotSchema = yup.object().shape({
    phone: yup.number().required("Required"),
  });

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      phone: process.env.NODE_ENV === "development" ? "082111503660" : "",
    },
    validationSchema: ForgotSchema,
    onSubmit: (values) => submit(values),
  });

  return (
    <div className="forgot">
      <div className="top">
        <Topbar onBack={() => navigate(-1)} title="Reset Password" />
        <div className="subtitle">
          Enter the phone number associated with your account.\nWe’ll send the
          password recovery instruction.'
        </div>
        <form onSubmit={handleSubmit} className="form">
          <div className="phone">
            <Input.Text
              name="phone"
              onChange={handleChange}
              value={values.phone?.replace(/[^0-9]/g, "")}
              placeholder="Enter Your Phone Number"
              inputMode="numeric"
            />
          </div>
          <button
            className={classNames("button", { loading: isLoading })}
            type="submit"
            disabled={!values.phone}
          >
            <div className="loader-wrapper">
              <div>Send</div>
              <div>
                <span className="loader" />
              </div>
            </div>
          </button>
        </form>
      </div>
      {!isEmpty(error) && (
        <Alert title="Error" message={error} onClick={() => setError("")} />
      )}
      {status === FormStatus.SUCCESS && (
        <Alert
          title="Success"
          message={message}
          onClick={() => navigate("/login")}
        />
      )}
    </div>
  );
};
