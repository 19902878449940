import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "stores";

import { Loader, Topbar } from "components";
import { FetchStatus } from "types";

export const Catalogue: FC = () => {
  const navigate = useNavigate();

  const { fetchRedeemProduct } = useStoreActions(
    (action) => action.redeemProduct.voucherItems
  );
  const { redeemProduct, status: redeemProductStatus } = useStoreState(
    (state) => state.redeemProduct.voucherItems
  );

  const { client_code } = useStoreState((state) => state.membership);

  const getProductRedeem = useCallback(() => {
    fetchRedeemProduct({ client_code });
  }, [fetchRedeemProduct, client_code]);

  useEffect(() => {
    getProductRedeem();
  }, [getProductRedeem, client_code]);

  return (
    <div className="catalogue-container">
      {redeemProductStatus === FetchStatus.LOADING && <Loader />}
      <Topbar title="Popular Catalogue" onBack={() => navigate(-1)} />
      <div className="content">
        {redeemProduct.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() =>
              navigate(`/catalogue/${item.id}`, { state: { data: item } })
            }
            style={{
              animation: `show .5s .5s ease forwards`,
              opacity: 0,
            }}
          >
            <div className="image">
              <img src={item.images[0]} alt="promo-banner" />
            </div>
            <div className="detail">
              <div className="availability">{item.name}</div>
              <div className="point">{item.point} Points</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
