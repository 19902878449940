import { ButtonHTMLAttributes, FC } from "react";
import classNames from "classnames";

import "./styles.scss";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isLoading?: boolean;
}

export const Button: FC<Props> = ({ label, isLoading, ...props }) => {
  return (
    <div className={props?.disabled ? "disabled" : ""}>
      <button
        className={classNames("button", { loading: isLoading })}
        {...props}
      >
        <div className="loader-wrapper">
          <div>{label}</div>
          <div>
            <span className="loader" />
          </div>
        </div>
      </button>
    </div>
  );
};
