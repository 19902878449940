import { action, thunk, computed } from 'easy-peasy'
import { FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { redeemProductService } from './service'
import { RedeemProduct, RedeemProductVoucherItemsModel } from './type'

export const redeemProductVoucherItemsModel: RedeemProductVoucherItemsModel = {
  redeemProduct: [],
  redeemProductDetail: {} as RedeemProduct,
  status: FetchStatus.LOADING,
  error: '',
  isEmpty: computed(
    (state) => isEmpty(state.redeemProduct) && state.status === FetchStatus.LOADED,
  ),

  // Actions
  setData: action((state, payload) => {
    state.redeemProduct = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  setDetail: action((state, payload) => {
    state.redeemProductDetail = payload
  }),

  addData: action((state, payload) => {
    state.redeemProduct = [...state.redeemProduct, ...payload]
  }),

  // Thunks
  fetchRedeemProduct: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await redeemProductService.fetchAllRedeemProduct(payload)
      action.setData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setData([])
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.ERROR)
    }
  }),

  fetchMoreRedeemProduct: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.FETCHING_MORE)
      const res = await redeemProductService.fetchAllRedeemProduct(payload)
      action.addData(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.ERROR)
    }
  }),
  fetchRedeemProductDetail: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await redeemProductService.fetchProductRedeemDetail(payload)
      action.setDetail(res.data)
      action.setStatus(FetchStatus.LOADED)
      action.setError('')
    } catch (e: any) {
      action.setError(e?.response?.data?.message || 'Network Error')
      action.setStatus(FetchStatus.ERROR)
    }
  }),
}