import { useState } from "react";
import { AxiosError } from "axios";

import { api } from "utils";
import { CLIENT_CODE } from "constant";
import { useStoreActions } from "stores";
import { FormStatus } from "types";

export type Response = {
  status: string;
  status_code: number;
  message: string;
  data: null;
};

type ForgotDto = {
  phone: string;
};

type UseForgot = {
  status: FormStatus;
  isLoading: boolean;
  submit: (dto: ForgotDto) => Promise<void>;
  message: string;
};

export const useForgot = (onError: (message: string) => void): UseForgot => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);
  const [message, setMessage] = useState<string>("");

  const submit = async (dto: ForgotDto) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      for (const key in dto) {
        // @ts-ignore
        formData.append(key, dto[key]);
      }
      formData.append("client_code", CLIENT_CODE);
      const res = await api.post<Response>("/forgot-password", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        // transformRequest: formData => formData,
      });
      const { message } = res.data;
      setStatus(FormStatus.SUCCESS);
      setMessage(message);
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log("ERROR_FORGOT", e);
        setIsLoading(false);
        onError(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
      setStatus(FormStatus.ERROR);
    }
  };

  return { status, isLoading, submit, message };
};
