import { api, isRetailClient, retailApi } from "utils";
import { PaginationData } from "types";

import { FetchHistoryParams, Transaction } from ".";

const fetchHistory = async (
  params: FetchHistoryParams
): Promise<PaginationData<Transaction>> => {
  try {
    const isRetail = await isRetailClient();

    const res = await (isRetail ? retailApi : api).get<
      PaginationData<Transaction>
    >("/customer/transaction/history", {
      params: {
        code_client: params.code_client,
        page: params.page,
        limit: params.limit,
        type: params.type,
        access_token: params.access_token,
      },
    });
    const { data } = res;
    return data;
  } catch (e: any) {
    throw e;
  }
};

export const transactionHistoryService = {
  fetchHistory,
};
