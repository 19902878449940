import { Data, PaginationData } from "types";
import { api, isRetailClient, retailApi } from "utils";

import {
  FetchAllProductRedeemParam,
  FetchDetailProductRedeemParam,
  RedeemProduct,
} from "./type";

const fetchAllRedeemProduct = async (
  params: FetchAllProductRedeemParam
): Promise<PaginationData<RedeemProduct>> => {
  try {
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).get("/redeem/product", {
      params: {
        client_code: params.client_code,
        type: "all",
        source: "all",
        status: "available",
        level: "client",
        limit: 100,
        page: params.page ? params.page : 1,
      },
    });
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

const fetchProductRedeemDetail = async (
  params: FetchDetailProductRedeemParam
): Promise<Data<RedeemProduct>> => {
  try {
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).get(
      `/redeem/product/${params.id}?client_code=${params.client_code}`
    );
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

export const redeemProductService = {
  fetchAllRedeemProduct,
  fetchProductRedeemDetail,
};
