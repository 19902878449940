import { useState } from "react";
import { AxiosError } from "axios";

import { api } from "utils";
import { CLIENT_CODE } from "constant";
import { useStoreActions } from "stores";
import { FormStatus } from "types";

type ChangePassDto = {
  passwordOld: string;
  password: string;
  passwordConfirmation: string;
};

export const useChangePassword = (
  onError: (message: string) => void
): [boolean, (dto: ChangePassDto) => Promise<void>, string] => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);

  const submit = async (dto: ChangePassDto) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("client_code", CLIENT_CODE);
      formData.append("old_password", dto.passwordOld);
      formData.append("password", dto.password);
      formData.append("password_confirmation", dto.passwordConfirmation);
      const res = await api.post("/customer/change-pin", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        // transformRequest: formData => formData,
      });
      const { data } = res.data;
      setStatus(FormStatus.SUCCESS);
    } catch (e) {
      if (e instanceof AxiosError) {
        console.log("ERROR_LOGIN", e);
        setIsLoading(false);
        onError(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  };

  return [isLoading, submit, status];
};
