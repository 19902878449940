import {
  api,
  getAccessToken,
  isRetailClient,
  retailApi,
} from "utils";
import { CLIENT_CODE } from "constant";

import { Profile, ProfileParams } from ".";

interface ProfileResponse {
  status: string;
  status_code: number;
  data: Profile;
}

const fetchProfile = async (
  params?: ProfileParams
): Promise<ProfileResponse> => {
  try {
    const isRetail = await isRetailClient();
    const accessToken = params?.token || (await getAccessToken());
    const client_code = params?.client_code || CLIENT_CODE;
    const res = await (isRetail ? retailApi : api).get<ProfileResponse>(
      `customer/profile?code_client=${client_code}&access_token=${accessToken}`
    );
    const { data } = res;
    return data;
  } catch (e) {
    throw e;
  }
};

export const ProfileService = {
  fetchProfile,
};
