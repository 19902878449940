import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { useStoreActions, useStoreState } from "stores";
import { FetchStatus, Membership } from "types";
import classNames from "classnames";
import { Alert, Loader, Topbar } from "components";
import { isEmpty } from "utils";

export const History = () => {
  const navigate = useNavigate();
  const [type, setType] = useState<string>("all");

  const { fetchHistory } = useStoreActions(
    (action) => action.transaction.history
  );
  const { data, status, error } = useStoreState(
    (state) => state.transaction.history
  );

  const location = useLocation();
  const member: Membership = location?.state?.data;
  const api_token: string = location?.state?.api_token;

  useEffect(() => {
    if (member && api_token && type) {
      fetchHistory({
        code_client: member.client.code,
        page: 1,
        limit: 100,
        type: type === "all" ? "" : type,
        access_token: api_token,
      });
    }
  }, [member, api_token, type]);

  const category = [
    { id: 0, label: "All", type: "all" },
    { id: 1, label: "Pending", type: "pending" },
    { id: 2, label: "Approved", type: "approved" },
    { id: 3, label: "Need Revision", type: "need-revision" },
    { id: 4, label: "Reject", type: "reject" },
    { id: 5, label: "Redeem", type: "redeem" },
    { id: 6, label: "Redeem Reservation", type: "redeem-reservation" },
    { id: 7, label: "Convert Point", type: "convert-point" },
    { id: 8, label: "Point Expiration", type: "point-expiration" },
  ];

  return (
    <div className="history">
      <div className="navigation">
        <Topbar title="History" onBack={() => navigate(-1)} />
        <div className="category">
          {category.map((item, index) => (
            <div
              key={index}
              className={classNames("tab", { active: type === item.type })}
              onClick={() => setType(item.type)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <div style={{ overflow: "auto" }}>
          {data.map((item, index) => (
            <div
              key={index}
              className="card"
              style={{
                opacity: 0,
                animation: `show-card 1s .${index}s ease forwards`,
              }}
            >
              <div className="content">
                <div style={{ flex: 1 }}>
                  <div className="type">{item.type}</div>
                  <div className="receipt">{item.receipt_no}</div>
                  <div className="product">{item.product.name}</div>
                  <div className="date">{moment(item.date).fromNow()}</div>
                </div>
                <div>
                  <div className="point">{item.point.redeem} Points</div>
                </div>
              </div>
            </div>
          ))}
          {data.length === 0 && <div className="empty">Empty History Data</div>}
        </div>
      )}
      {!isEmpty(error) && (
        <Alert message={error} title="Error" onClick={() => navigate(-1)} />
      )}
    </div>
  );
};
