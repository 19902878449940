import { useState, Dispatch, SetStateAction } from 'react'

import { api } from 'utils'
import { FormStatus } from 'types'
import { CLIENT_CODE } from 'constant'


type UseValidateRegisterNew = {
  status: FormStatus
  submit: (dto: any) => Promise<void>
  error: string
  setError: Dispatch<SetStateAction<string>>

}

interface ValidateNewRegistrationDto {
  name: string
  mobile_phone: string
  password: string
}

export const useValidateRegisterNew = (): UseValidateRegisterNew => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState('')

  const submit = async (dto: ValidateNewRegistrationDto) => {
    try {
      setError('')
      setStatus(FormStatus.LOADING)
      const formData = new FormData();
      for (let key in dto) {
        // @ts-ignore
        formData.append(key, dto[key]);
      }
      formData.append('client_code', CLIENT_CODE)
      const res = await api.post('/register/new/validate',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData,
      })
      await res.data;
      setStatus(FormStatus.SUCCESS)
    } catch (e: any) {
      setError(e?.response?.data?.message || 'Network Error')
      setStatus(FormStatus.ERROR)
    }
  }

  return {
    status,
    error,
    submit,
    setError
  }
}
