import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useStoreState } from "stores";
import { FormStatus } from "types";
import { api, getMemberAccessToken, isRetailClient, retailApi } from "utils";

type ConvertInquiry = {
  ld_conversion_limit: number;
  lucky_draw: number;
  redeem_point: number;
};

export const useConvertPoint = (luckyNumber: number) => {
  const [error, setError] = useState<string>("");
  const [convertInquiry, setConvertInquiry] = useState<ConvertInquiry>({
    ld_conversion_limit: 0,
    lucky_draw: 0,
    redeem_point: 0,
  });
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE);
  const { client_code } = useStoreState((state) => state.membership);

  const getConvertInquiry = useCallback(async () => {
    try {
      const isRetail = await isRetailClient();
      let URL = `convert-inquiry?code_client=${client_code}`;
      const res = await (isRetail ? retailApi : api).get(URL);
      const { data } = res;
      if (data.status_code === 200) {
        setConvertInquiry(data.data);
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  }, []);

  const convert = async () => {
    try {
      const isRetail = await isRetailClient();
      const accessToken = await getMemberAccessToken();
      const body = {
        code_client: client_code,
        access_token: accessToken,
        option: "rd_to_ld",
        lucky_draw_point_amount: luckyNumber,
      };
      const URL = "convert-point";
      const res = await (isRetail ? retailApi : api).post(URL, body);
      const { data } = res.data;
      setStatus(FormStatus.SUCCESS);
    } catch (e) {
      if (e instanceof AxiosError) {
        setError(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  };

  useEffect(() => {
    getConvertInquiry();
  }, [getConvertInquiry]);

  return { convertInquiry, convert, error, status };
};
