import { useCallback, useEffect, useState } from "react";
import { helperApi } from "utils";

export type ClientLink = {
  active: number;
  clientsCode: string;
  featureName: string;
  icon: string;
  id: number;
  name: string;
  type: string;
  webviewLink: string;
};

export const useClientLink = (clientCode: string) => {
  const [data, setData] = useState<ClientLink[]>([]);

  const fetchLink = useCallback(async () => {
    try {
      const res = await helperApi.get(`/ClientLink/${clientCode}`);
      const { data } = res.data;
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }, [clientCode]);

  useEffect(() => {
    if (clientCode) fetchLink();
  }, [clientCode, fetchLink]);

  return data;
};
