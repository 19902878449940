import { useState, Dispatch, SetStateAction } from 'react'

import { FormStatus } from 'types'
import { CLIENT_CODE } from 'constant'
import { api } from 'utils'
import { useStoreActions } from 'stores'


type UseRegisterNew = {
  status: FormStatus
  submit: (dto: any) => Promise<void>
  error: string
  setError: Dispatch<SetStateAction<string>>
}

type Response = {
  status: string
  status_code: number
  message: string
  data: {
    id: number
    code: string
    name: string
    // id_number: string
    profile_picture: string | null
    active: boolean
    total_expense: number
    point: {
      redeem: number
      lucky_draw: number
    }
    api_token: string
  }
}

export const useRegisterNew = (callback: (token: string) => void): UseRegisterNew => {
  const [status, setStatus] = useState<FormStatus>(FormStatus.IDLE)
  const [error, setError] = useState('')
  const { loggedIn } = useStoreActions((action) => action.auth)

  const submit = async (dto: any) => {
    try {
      setError('')
      setStatus(FormStatus.LOADING)
      const formData = new FormData()
      for (const key in dto) {
          formData.append(key, dto[key])
      }
      formData.append('client_code', CLIENT_CODE)
      const res = await api.post<Response>('/register/new',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData,
      })
      const { data } = res.data
      setStatus(FormStatus.SUCCESS)
      loggedIn(data.api_token)
      callback(data.api_token)
    } catch (e: any) {
      setError(e?.response?.data?.message || 'Network Error')
      setStatus(FormStatus.ERROR)
    } finally {
      setStatus(FormStatus.IDLE)
    }
  }

  return {
    status,
    error,
    submit,
    setError,
  }
}
