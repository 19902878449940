import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { FetchStatus } from "types";
import { api, retailApi } from "utils";

export type MemberProfile = {
  active: boolean;
  address: string;
  area: string;
  barcode: string;
  birth_date: string;
  birth_place: string;
  blood: string;
  card_type: {
    active: boolean;
    default: boolean;
    expired_period: number;
    id: number;
    image: string;
    level_down_evaluation_days: number;
    level_up_evaluation_days: number;
    minimum_expense: number;
    multiplier_point: number;
    name: string;
    target_amount: number;
  };
  code: string;
  email: string;
  facsimile: string;
  gender: string;
  id: number;
  id_number: string;
  id_type: {
    code: string;
    id: number;
    name: string;
  };
  interests: string[];
  marital_status: string;
  name: string;
  nationality: string;
  occupation: string;
  phone: {
    business: string;
    home: string;
    mobile: string;
  };
  point: {
    lucky_draw: number;
    redeem: number;
  };
  preferred_contact: string;
  profile_picture: string;
  religion: string;
  socials: string[];
  total_expense: number;
};

export const useMemberProfile = (
  clientCode: string,
  accessToken: string,
  clientType: string
) => {
  const [data, setData] = useState<MemberProfile>({} as MemberProfile);
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.LOADING);

  const fetchProfile = useCallback(async () => {
    try {
      const url = `/customer/profile?code_client=${clientCode}&access_token=${accessToken}`;
      const res = await (clientType === "retail" ? retailApi : api).get(url);
      const { data } = res.data;
      setData(data);
      setStatus(FetchStatus.LOADED);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e?.response?.data?.message || "Network Error");
      } else {
        console.log("Unexpected error", e);
      }
    }
  }, [clientCode, accessToken]);

  useEffect(() => {
    if (clientCode && accessToken && clientType) fetchProfile();
  }, [clientCode, accessToken, clientType, fetchProfile]);

  return { data, status };
};
