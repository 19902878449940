import { action, thunk, computed } from "easy-peasy";

import {
  destroyAccessToken,
  setDefaultAuthJwt,
  getAccessToken,
  persistAccessToken,
  persistMemberAccessToken,
  setDefaultRetailJwt,
  clearStorage,
  getMemberAccessToken,
} from "utils";
import { FetchStatus, AuthStatus } from "types";

import { AuthModel, LoginDto, AuthService } from ".";

export const auth: AuthModel = {
  status: FetchStatus.LOADING,
  authStatus: AuthStatus.LOADING,
  error: {},

  // Computed
  isAuthenticated: computed(
    (state) => state.authStatus === AuthStatus.AUTHENTICATED
  ),

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload;
  }),
  setAuthStatus: action((state, payload) => {
    state.authStatus = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),

  // Thunks
  login: thunk(async (action, dto: LoginDto) => {
    try {
      action.setStatus(FetchStatus.LOADING);
      const res = await AuthService.login(dto);
      const { data } = res;
      action.loggedIn(data.api_token);
      action.setStatus(FetchStatus.LOADED);
      action.setError({});
    } catch (e) {
      action.setError(e);
      action.setStatus(FetchStatus.ERROR);
    }
  }),
  checkAuth: thunk(async (action) => {
    action.setAuthStatus(AuthStatus.LOADING);
    const accessToken = await getAccessToken();
    const accessTokenMember = await getMemberAccessToken();
    if (accessTokenMember) action.loggedInMember(accessTokenMember);
    if (!accessToken) action.setAuthStatus(AuthStatus.UNAUTHENTICATED);
    else action.loggedIn(accessToken);
  }),
  loggedIn: thunk(async (action, payload) => {
    await Promise.all([
      persistAccessToken(payload),
      setDefaultAuthJwt(payload),
    ]);
    action.setAuthStatus(AuthStatus.AUTHENTICATED);
  }),
  loggedInMember: thunk(async (action, payload) => {
    await Promise.all([
      persistMemberAccessToken(payload),
      setDefaultRetailJwt(payload),
    ]);
    action.setAuthStatus(AuthStatus.AUTHENTICATED);
  }),
  loggedOut: thunk(async (action, callback) => {
    await Promise.all([destroyAccessToken(), clearStorage()]);
    setDefaultAuthJwt("");
    setDefaultRetailJwt("");
    action.setAuthStatus(AuthStatus.UNAUTHENTICATED);
    callback();
  }),
};
