import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import {
  SplashScreen,
  Login,
  Membership,
  Home,
  History,
  Reward,
  Promotion,
  Catalogue,
  CatalogueDetail,
  Event,
  Profile,
  EventDetail,
  EditProfile,
  ConvertPoint,
  RegisterName,
  RegisterPassword,
  RegisterOTP,
  AddCard,
  ScanQr,
  InputMember,
  CreateMembership,
  ChangePassword,
  Store,
  Snap,
  ForgotPassword,
} from "pages";
import { useStoreActions, useStoreState } from "stores";
import { AuthStatus } from "types";
import { useEffect, useState } from "react";
import { PromotionDetail } from "pages/promotion/detail";
import { Loader } from "components/loader";

export const App = () => {
  const { authStatus, isAuthenticated } = useStoreState((state) => state.auth);
  const { checkAuth } = useStoreActions((action) => action.auth);
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      await checkAuth();
      setAuthChecked(true);
    };

    fetchAuthStatus();
  }, [checkAuth]);

  const PrivateRoutes = () => {
    if (!authChecked) {
      return <Loader />;
    }
    if (!isAuthenticated && authStatus !== AuthStatus.LOADING) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  };

  return (
    <div className="container" id="container">
      <Routes>
        <Route path="/" element={<SplashScreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/register/name" element={<RegisterName />} />
        <Route path="/register/password" element={<RegisterPassword />} />
        <Route path="/register/OTP" element={<RegisterOTP />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/membership" element={<Membership />} />
          <Route path="/member/activate" element={<InputMember />} />
          <Route path="/member/create" element={<CreateMembership />} />
          <Route path="/addcard" element={<AddCard />} />
          <Route path="/scanqr" element={<ScanQr />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<EditProfile />} />
          <Route path="/event" element={<Event />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route path="/history" element={<History />} />
          <Route path="/reward" element={<Reward />} />
          <Route path="/promotion" element={<Promotion />} />
          <Route path="/store" element={<Store />} />
          <Route path="/promotion/:id" element={<PromotionDetail />} />
          <Route path="/catalogue" element={<Catalogue />} />
          <Route path="/convert" element={<ConvertPoint />} />
          <Route path="/catalogue/:id" element={<CatalogueDetail />} />
          <Route path="/snap" element={<Snap />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
};
