import {
  CLIENT_MEMBER_CODE,
  CLIENT_TYPE,
  STORAGE_ACCESS_KEY,
  STORAGE_MEMBER_ACCESS_KEY,
} from "constant";

export const persistAccessToken = async (token: string): Promise<void> => {
  return localStorage.setItem(STORAGE_ACCESS_KEY, token);
};

export const destroyAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_ACCESS_KEY);
};

export const getAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_ACCESS_KEY);
};

export const persistMemberAccessToken = async (
  token: string
): Promise<void> => {
  return localStorage.setItem(STORAGE_MEMBER_ACCESS_KEY, token);
};

export const destroyMemberAccessToken = async (): Promise<void> => {
  return localStorage.removeItem(STORAGE_MEMBER_ACCESS_KEY);
};

export const removeClientType = async (): Promise<void> => {
  return localStorage.removeItem(CLIENT_TYPE);
};

export const removeClientCode = async (): Promise<void> => {
  return localStorage.removeItem(CLIENT_MEMBER_CODE);
};

export const getMemberAccessToken = async (): Promise<string | null> => {
  return localStorage.getItem(STORAGE_MEMBER_ACCESS_KEY);
};

export const perssitClientCode = async (client_code: string): Promise<void> => {
  return localStorage.setItem(CLIENT_MEMBER_CODE, client_code);
};

export const getClientCode = async (): Promise<string | null> => {
  return localStorage.getItem(CLIENT_MEMBER_CODE);
};

export const setClientType = async (type: string): Promise<void> => {
  return localStorage.setItem(CLIENT_TYPE, type);
};

export const getClientType = async (): Promise<string | null> => {
  return localStorage.getItem(CLIENT_TYPE);
};

export const clearMerchant = async (): Promise<void> => {
  await destroyMemberAccessToken();
  await removeClientType();
  await removeClientCode();
  return;
};

export const clearStorage = async (): Promise<void> => {
  return localStorage.clear();
};
