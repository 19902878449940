import { InputText } from './text'
import { InputPassword } from './password'
import { InputPhone } from './phone'
import { InputDate } from './date'

type InputType = typeof InputText & {
  Text: typeof InputText
  Password: typeof InputPassword
  Phone: typeof InputPhone
  Date: typeof InputDate
}

export const Input = InputText as InputType

Input.Text = InputText
Input.Password = InputPassword
Input.Phone = InputPhone
Input.Date = InputDate
