import { useCallback, useEffect, useState } from "react";
import { helperApi } from "utils";

export interface Client {
  id: number;
  clientCode: string;
  clientCategoryId: number;
  name: string;
  image: string;
  tag: string;
  description: string;
  canRegister: number;
  canEarning: number;
  canRedeem: number;
  canConvert: number;
  isShown: number;
  clientType: string;
}

export interface UseGetMerchant {
  data: Client[];
}

export const useGetMerchant = (): UseGetMerchant => {
  const [data, setData] = useState<Client[]>([]);

  const fetchCategory = useCallback(async () => {
    try {
      const res = await helperApi.get(`/MasterCategory`);
      const { data } = res.data;
      fetchMerchant(data[0].id);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchMerchant = useCallback(async (id: number) => {
    try {
      const res = await helperApi.get(`/ClientInfo/${id}/0/10`);
      const { data } = res.data;
      setData(data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchCategory();
  }, [fetchCategory]);

  return { data };
};
