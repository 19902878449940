import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreState } from "stores";
import { useCreateMembership, useMerchantData } from "pages/add_card/hooks";
import { Alert, Button, Dropdown, Input, Loader, Topbar } from "components";
import { FetchStatus, FormStatus } from "types";
import "react-datepicker/dist/react-datepicker.css";
import { getClientType } from "utils";

export interface Interest {
  value: number;
  label: string;
}

export const CreateMembership: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );
  const [name, setName] = useState<string>(profile?.name || "");
  const [phone, setPhone] = useState<string>(
    profile?.phone?.mobile?.slice(2) || ""
  );
  const [email, setEmail] = useState<string>(profile?.email || "");
  const [date, setDate] = useState<Date | null>(
    profile?.birth_date ? new Date(profile?.birth_date) : null
  );
  const [birth_date, setBirtDate] = useState<string>("");
  const [gender, setGender] = useState(profile?.gender || "");
  const [typeID, setTypeID] = useState<any>("");
  const [identity, setIdentity] = useState("");
  const [province, setProvince] = useState<any>(profile.address?.province);
  const [city, setCity] = useState<any>(profile.address?.city);
  const [area, setArea] = useState<any>("");
  const [address, setAddress] = useState(profile.address?.address);
  const [status, setStatus] = useState<any>(profile.marital_status);
  const [interests, setInterests] = useState<any>([]);
  const [instagram, setInstagram] = useState<string>("");
  const [isCheck, setIsCheck] = useState(false);
  const statusList = [
    { name: "Single", id: 1 },
    { name: "Married", id: 2 },
  ];
  const [client_type, setClientType] = useState<string | null>("");

  const getType = async () => {
    const type = await getClientType();
    setClientType(type);
  };

  useEffect(() => {
    getType();
  }, []);

  const merchant = location?.state?.merchant;

  const {
    submit,
    error,
    setError,
    status: CreateStatus,
  } = useCreateMembership();

  useEffect(() => {
    if (!merchant) {
      navigate("/addcard");
    }
  }, [merchant]);

  const {
    province: provinceList,
    area: areaList,
    typeID: typeIDList,
    interest: interestList,
    required,
    city: cityList,
    fetchCity,
    loading: loadingMerchant,
  } = useMerchantData(merchant?.clientCode);

  useEffect(() => {
    if (province) {
      fetchCity(province.value);
    }
  }, [province]);

  const loading =
    statusProfile === FetchStatus.LOADING ||
    CreateStatus === FormStatus.LOADING ||
    loadingMerchant;

  const formSubmit: any = {
    name,
    email,
    address,
    mobile_phone: `62${phone}`,
    id_type: typeID?.value,
    birth_date,
    "interest[]": interests.map((obj: Interest) => obj.value),
    id_number: identity,
    marital_status: status?.label,
    province: province?.value,
    preferred_contact: "phone",
    city: city?.value,
    area: area?.value,
    gender,
    client_type: client_type?.toLowerCase(),
    ign: instagram,
  };

  const filteredFormSubmit = Object.fromEntries(
    Object.entries(formSubmit).filter(([_, value]) => value !== undefined)
  );

  return (
    <>
      {loading && <Loader />}
      <div className="create-membership-container">
        <Topbar title="Create Membership" onBack={() => navigate(-1)} />
        <div style={{ padding: "1rem" }} className="content">
          <label>Name</label>
          <Input.Text
            name="name"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <label>Mobile Number</label>
          <Input.Phone
            name="mobile"
            placeholder="Enter your mobile number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <label>Email</label>
          <Input.Text
            name="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input.Date
            label="Birth Date"
            date={date}
            setDate={setDate}
            setDateStr={setBirtDate}
          />
          <label>Gender</label>
          <div className="gender-container">
            <span className="gender-label">
              <input
                type="radio"
                value="F"
                checked={gender === "F"}
                onChange={() => setGender("F")}
              />
              Female
            </span>
            <span className="gender-label">
              <input
                type="radio"
                value="M"
                checked={gender === "M"}
                onChange={() => setGender("M")}
              />
              Male
            </span>
          </div>
          <Dropdown
            label="Identity Document"
            options={typeIDList}
            onSelectChange={setTypeID}
          />
          <label>Identity Number</label>
          <Input.Text
            name="identity"
            placeholder="Enter your identity number"
            value={identity}
            onChange={(e) => setIdentity(e.target.value)}
          />
          <Dropdown
            label="Province"
            options={provinceList}
            onSelectChange={setProvince}
          />
          <Dropdown label="City" options={cityList} onSelectChange={setCity} />
          <Dropdown label="Area" options={areaList} onSelectChange={setArea} />
          <label>Address</label>
          <Input.Text
            name="address"
            placeholder="Enter your address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <Dropdown
            label="Status"
            options={statusList}
            onSelectChange={setStatus}
          />
          <Dropdown
            label="Interest"
            options={interestList}
            onSelectChange={setInterests}
            isMulti
          />
          <label>Instagram Username</label>
          <Input.Text
            name="instagram"
            placeholder="Enter your instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
          <Button
            label="Continue"
            onClick={() => {
              submit(filteredFormSubmit);
            }}
          />
        </div>
      </div>
      {error && (
        <Alert message={error} title="Error" onClick={() => setError("")} />
      )}
      {CreateStatus === FormStatus.SUCCESS && (
        <Alert
          message={
            "Enjoy your membership benefits and go to merchant page now to explore more."
          }
          title="Create Membership Success!"
          onClick={() => navigate("/membership")}
        />
      )}
    </>
  );
};
