import { useEffect, useState } from "react";
import classNames from "classnames";
import { QRFunc } from "react-qrbtf";
import copy from "copy-to-clipboard";

import { Alert, EmptyComponent, Loader } from "components";
import { useStoreActions, useStoreState } from "stores";

import { useMemberships } from "./hooks";
import { Card } from "./components";
import { useNavigate } from "react-router-dom";
import { Icons } from "assets";
import { clearMerchant } from "utils";

export const Membership = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { deleteMember } = useStoreActions((action) => action.membership);
  const { profile } = useStoreState((state) => state.profile);
  const [cleaned, setCleaned] = useState<boolean>(false);

  const [showCode, setShowCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
  const [merchantId, setMerchantId] = useState<number>(0);

  useEffect(() => {
    if (isAuthenticated && cleaned) fetchProfile();
  }, [isAuthenticated, cleaned]);

  const { data: cards, isLoading, refetch } = useMemberships();

  const handleDelete = (id: number) => {
    setDeleteAlert(false);
    deleteMember(id);
    refetch();
  };

  useEffect(() => {
    const cleanup = async () => {
      await clearMerchant();
      setCleaned(true);
    };
    cleanup();
  }, []);

  return (
    <div className="home">
      {isLoading && <Loader />}
      <div
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          border: "1px solid #f5f5f5",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            padding: "1rem",
          }}
        >
          <div
            style={{
              borderRadius: "40%",
              height: 35,
              width: 35,
              border: "1px solid #ddd",
              backgroundColor: "white",
              backgroundImage: `url(${
                profile?.profile_picture || require("assets/images/user.png")
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            style={{
              flexGrow: 1,
            }}
          >
            <div style={{ fontSize: 12, marginBottom: 1 }}>Welcome back,</div>
            <div style={{ fontSize: 20, fontWeight: "bold" }}>
              {profile?.name}
            </div>
          </div>
          <div
            style={{
              height: 26,
              width: 26,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              padding: 5,
              borderRadius: "40%",
              cursor: "pointer",
            }}
            onClick={() => navigate("/profile")}
          >
            <img
              style={{
                height: 20,
              }}
              src={Icons.profile}
              alt="icon"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 1rem",
          }}
        >
          <div style={{ fontSize: 16 }}>Membership Cards</div>
          <div
            style={{
              height: 26,
              width: 26,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 5,
              borderRadius: "40%",
            }}
          >
            <img
              style={{
                height: 20,
                cursor: "pointer",
              }}
              src={require("assets/images/plus.png")}
              alt="icon"
              onClick={() => navigate("/addcard")}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        {cards.map((item, index) => (
          <Card
            data={item}
            index={index}
            key={index}
            openCode={(code) => {
              setShowCode(true);
              setCode(code);
            }}
            setDeleteAlert={setDeleteAlert}
            setMerchantId={setMerchantId}
          />
        ))}
        {cards.length === 0 && !isLoading && (
          <EmptyComponent title="No Card Available" message="Add Card Now" />
        )}
      </div>
      <div
        className={classNames("modal", { show: showCode })}
        onClick={() => setShowCode(false)}
      >
        <div className="code" onClick={(e) => e.stopPropagation()}>
          <QRFunc value={code} type="round" posType="round" />
          <div className="voucher-code" onClick={() => copy(code)}>
            <span>{code}</span>
            <img
              src={require("assets/images/copy.png")}
              alt="copy"
              className="copy"
            />
          </div>
        </div>
      </div>
      {deleteAlert ? (
        <Alert
          title="Confirmation"
          message="Are you sure you want to delete this membership?"
          onClick={() => handleDelete(merchantId)}
          onCancel={() => setDeleteAlert(false)}
          deleting
        />
      ) : null}
    </div>
  );
};
