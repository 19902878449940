import { TransactionHistoryModel, transactionHistoryModel } from './history'

export * from './history'

export interface TransactionModel {
  history: TransactionHistoryModel
}

export const transaction: TransactionModel = {
  history: transactionHistoryModel
}
