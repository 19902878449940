import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "stores";
import { Alert, Button, Input, Loader, Topbar } from "components";
import { FetchStatus } from "types";
import { useEditProfile } from "./hooks";
import { Icons, Images } from "assets";

export const EditProfile: FC = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string>("");
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleProfilePictureClick = () => {
    fileInputRef.current?.click();
  };
  const [name, setName] = useState<string>(profile?.name || "");
  const [phone, setPhone] = useState<string>(
    `${profile?.phone?.mobile?.slice(2)}` || ""
  );
  const [email, setEmail] = useState<string>(profile?.email || "");
  const [birthDate, setBirthDate] = useState<any>(profile?.birth_date || "");
  const [gender, setGender] = useState<any>(profile?.gender || "");
  const [religion, setReligion] = useState<any>(profile?.religion || "");
  const [maritalStatus, setMaritalStatus] = useState<any>(
    profile?.marital_status || ""
  );
  const [province, setProvince] = useState(
    profile?.address?.province ? profile?.address?.province.id.toString() : ""
  );
  const [city, setCity] = useState(
    profile?.address?.city ? profile?.address?.city.id.toString() : ""
  );
  const [address, setAddress] = useState(
    profile?.address?.address ? profile?.address?.address : ""
  );
  const [profile_picture, setProfile_picture] = useState(
    profile?.profile_picture ? profile?.profile_picture : ""
  );
  useEffect(() => {
    if (isAuthenticated) fetchProfile();
  }, [isAuthenticated, fetchProfile]);

  useEffect(() => {
    if (profile) {
      setName(profile.name || "");
      setPhone(`${profile.phone?.mobile?.slice(2)}` || "");
      setEmail(profile.email || "");
      setBirthDate(profile.birth_date || "");
      setGender(profile.gender || "");
      setReligion(profile.religion || "");
      setMaritalStatus(profile.marital_status || "");
      setProvince(
        profile.address?.province ? profile.address.province.id.toString() : ""
      );
      setCity(profile.address?.city ? profile.address.city.id.toString() : "");
      setAddress(profile.address?.address || "");
      setProfile_picture(profile.profile_picture || "");
    }
  }, [profile]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    setCurrentImage(selectedFiles?.[0]);
    setPreviewImage(URL.createObjectURL(selectedFiles?.[0]));
  };

  const loading = statusProfile === FetchStatus.LOADING;

  const { isLoading, error, submit, setError, success } = useEditProfile();

  const formSubmit: any = { name, email, address, mobile_phone: `62${phone}` };

  if (currentImage) {
    formSubmit.profile_picture = currentImage;
  }

  return (
    <div className="edit-profile-container">
      <Topbar title="Edit Profile" onBack={() => navigate(-1)} />
      {loading && <Loader />}
      <div style={{ padding: "1rem" }}>
        <div className="profile-picture-box">
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <div className="profile-picture-container">
            {previewImage ? (
              <img
                className="profile-picture"
                src={previewImage}
                alt="profile-picture"
              />
            ) : profile_picture ? (
              <img
                className="profile-picture"
                src={profile_picture}
                alt="profile-picture"
              />
            ) : (
              <img
                className="profile-picture-placeholder"
                src={Icons.profile}
                alt="profile-picture"
              />
            )}
            <div className="icon-container" onClick={handleProfilePictureClick}>
              <img src={Icons.camera} className="icon" alt="icon" />
            </div>
          </div>
        </div>
        <div className="label">Name</div>
        <Input.Text
          name="name"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className="label">Mobile Number</div>
        <Input.Phone
          name="mobile"
          placeholder="Enter your mobile number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          // disabled
        />
        <div className="label">Email</div>
        <Input.Text
          name="email"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="label">Address</div>
        <Input.Text
          name="address"
          placeholder="Enter your address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Button
          label="Save"
          onClick={() => {
            submit(formSubmit);
          }}
          isLoading={isLoading}
        />
      </div>
      {success && (
        <Alert
          title="Profile Updated"
          message="Your data has been saved"
          onClick={() => navigate(-1)}
        />
      )}
      {error && (
        <Alert message={error} title="Error" onClick={() => setError("")} />
      )}
    </div>
  );
};
