import { Alert, Button, Input, Topbar } from "components";
import { FC, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "utils";

export const RegisterName: FC = () => {
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const navigate = useNavigate();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  return (
    <div className="register-name">
      <Topbar title="Register" onBack={() => navigate(-1)} />
      <div className="input-phone">
        <div className="input-name">
          <label>Name</label>
          <Input.Text
            name="name"
            placeholder="Enter your name"
            onChange={handleNameChange}
            value={name}
          />
        </div>
        <label>Phone</label>
        <Input.Phone
          name="phone"
          placeholder="Enter your phone number"
          onChange={handlePhoneChange}
          value={phone}
        />
      </div>
      <Button
        label="Continue"
        onClick={() =>
          navigate("/register/password", {
            state: { phone: `62${phone}`, name },
          })
        }
        disabled={!phone || !name}
      />
    </div>
  );
};
