import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

import { EventItem, useStoreActions, useStoreState } from "stores";

import { Topbar } from "components";

import { isEmpty } from "utils";

export const EventDetail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { fetchEventDetail } = useStoreActions((action) => action.event);
  const { eventDetail } = useStoreState((state) => state.event);

  const [item, setItem] = useState<EventItem>(eventDetail);

  let { id } = useParams();
  const data = location?.state?.data as EventItem;

  useEffect(() => {
    if (id) fetchEventDetail(id);
  }, [fetchEventDetail, id]);

  useEffect(() => {
    setItem(data || eventDetail);
  }, [eventDetail, data]);

  return (
    <div className="event-detail-container">
      {!isEmpty(item) && (
        <>
          <Topbar title="Event" onBack={() => navigate(-1)} />
          <div className="content">
            <img className="image" src={item.photos[0]} alt="event-banner" />
            <div className="detail">
              <div className="title">{item.name}</div>
              <div className="date">
                <img
                  className="icon"
                  src={require("assets/icons/date.png")}
                  alt="icon"
                />
                <span>
                  {moment(item.period.start).format("DD MMM YYYY")} -{" "}
                  {moment(item.period.end).format("DD MMM YYYY")}
                </span>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
