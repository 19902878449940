import axios from "axios";

import { BASE_RETAIL, BASE_URL, HELPER_URL } from "constant";
import store from "stores";
import { getClientType } from "utils/storage";

export const api = axios.create({
  baseURL: BASE_URL,
});

export const retailApi = axios.create({
  baseURL: BASE_RETAIL,
});

export const helperApi = axios.create({
  baseURL: HELPER_URL,
});

export const setDefaultAuthJwt = (jwt: string): void => {
  api.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401)
        store.dispatch.auth.loggedOut(() =>
          console.log("LOGGED OUT: Unauthenticated")
        );
      return Promise.reject(error);
    }
  );
  helperApi.defaults.headers.common["Authorization"] = "Bearer " + jwt;
};

export const setDefaultRetailJwt = (jwt: string): void => {
  retailApi.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  retailApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401)
        store.dispatch.auth.loggedOut(() =>
          console.log("LOGGED OUT: Unauthenticated")
        );
      return Promise.reject(error);
    }
  );
};

export const isRetailClient = async () => {
  const clientType = await getClientType();
  return clientType?.toLowerCase() === "retail";
};
