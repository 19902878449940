import { Icons } from "assets";
import { Alert, Button, Input, Topbar } from "components";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStoreActions, useStoreState } from "stores";
import { useActivateMembership } from "pages/add_card/hooks";
import "./styles.scss";
import { getClientType } from "utils";
import { FormStatus } from "types";

export const InputMember: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );
  const [number, setNumber] = useState("");
  const [phone, setPhone] = useState<string>(
    `${profile?.phone?.mobile?.slice(2)}` || ""
  );
  const [client_type, setClientType] = useState<string>("");

  const merchant = location?.state?.merchant;

  const { submit, error, setError, status } = useActivateMembership();

  const getType = async () => {
    const type = await getClientType();
    if (type) {
      setClientType(type);
    }
  };

  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    if (!merchant) {
      navigate("/addcard");
    }
  }, [merchant]);

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumber(e.target.value);
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleSubmit = () => {
    submit({ phone, number, clientCode: merchant.clientCode, client_type });
  };

  return (
    <div className="input-member-container">
      <Topbar title="" onBack={() => navigate(-1)} />
      <div className="top-content">
        <img src={Icons.addCard} />
        <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
          Input Member Number
        </span>
        <span>
          To activate this member card, please input your member number or phone
          number
        </span>
      </div>
      <div className="bottom-content">
        <label>Member Number</label>
        <Input.Text
          name="Member Number"
          placeholder="Enter your member number here"
          onChange={handleNumber}
          value={number}
        />
        <div className="line">
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              width: "3rem",
            }}
          />
          <span>or</span>
          <div
            style={{
              height: "1px",
              backgroundColor: "black",
              width: "3rem",
            }}
          />
        </div>
        <label>Phone Number</label>
        <Input.Phone
          name="Phone Number"
          placeholder="Enter your phone number here"
          onChange={handlePhone}
          value={phone}
        />
      </div>
      <Button
        label="Activate Membership"
        onClick={handleSubmit}
        disabled={!phone && !number}
      />
      {error && (
        <Alert title="Error" message={error} onClick={() => setError("")} />
      )}
      {status === FormStatus.SUCCESS && (
        <Alert
          message={
            "Enjoy your membership benefits and go to merchant page now to explore more."
          }
          title="Create Membership Success!"
          onClick={() => navigate("/membership")}
        />
      )}
    </div>
  );
};
