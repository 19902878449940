import { api, isRetailClient, retailApi } from "utils";

import { GetAllOutletResponse, GetAllOutletParams, OutletService } from ".";

const getAllOutlet = async (
  params: GetAllOutletParams
): Promise<GetAllOutletResponse> => {
  try {
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).get<GetAllOutletResponse>(
      "/master/store",
      { params }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const outletService: OutletService = {
  getAllOutlet,
};
