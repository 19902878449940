import { action, thunk, thunkOn } from 'easy-peasy'

import { AuthStatus, FetchStatus, FormStatus, Membership } from 'types'
import { isEmpty } from 'utils'

import {  MembershipModel, MembershipService } from '.'

export const membership: MembershipModel = {
  membership: {} as Membership,
  client_code: '',
  memberToken:'',
  error: {},
  status: FormStatus.IDLE,

  // Actions
  setMembership: action((state, payload) => {
    state.membership = payload
  }),
  setMemberToken: action((state, payload) => {
    state.memberToken = payload
  }),
  setClientCode: action((state, payload) =>{
    state.client_code = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),


  // Thunks
  deleteMember: thunk(async (action, id: number) => {
    try {
      action.setStatus(FormStatus.LOADING)
      const res = await MembershipService.deleteMembership(id)
      const { data } = res
      action.setStatus(FormStatus.SUCCESS)
      action.setError({})
    } catch (e) {
      action.setError(e)
      action.setStatus(FormStatus.ERROR)
    }
  }),
}