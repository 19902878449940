import { useCallback, useEffect, useState } from 'react'
import { helperApi } from 'utils'

export type ClientInfo = {
  canConvert: number
  canEarning: number
  canRedeem: number
  canRegister: number
  clientCategoryId: number
  clientCode: string
  description: string
  id: number
  image: string
  isShown: number
  name: string
  tag: string
}

export const useClientInfo = (clientCode: string) => {
  const [data, setData] = useState<ClientInfo[]>([])

  const fetchLink = useCallback(async () => {
    try {
      const res = await helperApi.get(`/ClientInfo/${clientCode}`)
      const { data } = res.data
      setData(data)
    } catch (e) {
      console.log(e)
    }
  }, [clientCode])

  useEffect(() => {
    if (clientCode) fetchLink()
  }, [clientCode, fetchLink])

  return data
}

