import { useState, useCallback, useEffect } from 'react'
import { useStoreState } from 'stores'
import { FetchStatus } from 'types'
import { api, isEmpty } from 'utils'

export type Membership = {
  active: boolean;
  barcode: string;
  customer_integration_id: number;
  phone: string;
  client_type: string;
  client: {
    code: string;
    contact_address: string;
    contact_email: string;
    contact_name: string;
    contact_phone: string;
    facebook_link: string;
    id: number;
    instagram_link: string;
    latitude: string;
    line_username: string;
    logo: string;
    longitude: string;
    name: string;
    operational_hours: string;
    parking_information: string;
    twitter_link: string;
    whatsapp_number: string;
    youtube_link: string;
  };
  expired_at: string;
  id: number;
  point: {
    lucky_draw: number;
    redeem: number;
  };
}

interface Response {
  status: string
  status_code: number
  data: Membership[]
}

type UseMemberships = {
  data: Membership[]
  isLoading: boolean
  isError: boolean
  refetch: () => void
}

export const useMemberships = (): UseMemberships => {
  const { profile } = useStoreState(state => state.profile)
  
  const [data, setData] = useState<Membership[]>([])
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.LOADING)

  const fetchMemberships = useCallback(async () => {
    try {
      setStatus(FetchStatus.LOADING)
      const res = await api.get<Response>('customer/memberships')
      const { data } = res.data
      setData(data)
      setStatus(FetchStatus.LOADED)
    } catch (e) {
      setStatus(FetchStatus.ERROR)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(profile)) fetchMemberships()
  }, [profile])

  return {
    data,
    isLoading: status === FetchStatus.LOADING,
    isError: status === FetchStatus.ERROR,
    refetch: fetchMemberships
  }
}
