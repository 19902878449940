import { Topbar } from "components";
import { useNavigate } from "react-router-dom";

export const Snap = () => {
  const navigate = useNavigate();

  return (
    <div className="snap">
      <Topbar onBack={() => navigate(-1)} title="Snap" />
      <div className="content">
        <h1>SNAP</h1>
      </div>
    </div>
  );
};
