import { useEffect, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import { useStoreActions, useStoreState } from "stores";

import { useLogin } from "./hooks";
import { isEmpty } from "utils";
import { Alert, Input } from "components";
import { AuthStatus } from "types";

export const Login = () => {
  const navigate = useNavigate();

  const { isAuthenticated, authStatus } = useStoreState((state) => state.auth);

  const [error, setError] = useState<string>("");
  const [isLoading, submit] = useLogin(setError);

  const SignInSchema = yup.object().shape({
    phone: yup.number().required("Required"),
    password: yup.string().required("Required"),
  });

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      phone: process.env.NODE_ENV === "development" ? "082111503660" : "",
      password: process.env.NODE_ENV === "development" ? "000000" : "",
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => submit(values),
  });

  useEffect(() => {
    if (isAuthenticated && authStatus !== AuthStatus.LOADING)
      navigate("/membership", { replace: true });
  }, [isAuthenticated, authStatus, navigate]);

  return (
    <div className="login">
      <div className="top">
        <div className="topbar" />
        <div className="title">Sign In</div>
        <div className="subtitle">Sign in to your account</div>
        <form onSubmit={handleSubmit} className="form">
          <div className="phone">
            <Input.Text
              name="phone"
              onChange={handleChange}
              value={values.phone?.replace(/[^0-9]/g, "")}
              placeholder="Enter Your Phone Number"
              inputMode="numeric"
            />
          </div>
          <div className="password">
            <Input.Password
              label="Password"
              name="password"
              onChange={handleChange}
              value={values.password}
              placeholder="Enter Your Password"
            />
          </div>
          <button
            className={classNames("button", { loading: isLoading })}
            type="submit"
          >
            <div className="loader-wrapper">
              <div>Sign In</div>
              <div>
                <span className="loader" />
              </div>
            </div>
          </button>
          <div onClick={() => navigate("/forgot")} className="forgot-password">
            Forgot password?
          </div>
        </form>
      </div>
      <div className="register" onClick={() => navigate("/register/name")}>
        Don't have account? <span>Register</span>
      </div>
      {!isEmpty(error) && (
        <Alert
          title="Login Failed"
          message={error}
          onClick={() => setError("")}
        />
      )}
    </div>
  );
};
