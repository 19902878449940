import { api, getClientCode, isRetailClient, retailApi } from "utils";
import {
  GetAllPromoResponse,
  GetAllPromoParams,
  PromoService,
  GetPromoDetailResponse,
} from ".";

const getAllPromo = async (
  params: GetAllPromoParams
): Promise<GetAllPromoResponse> => {
  const isRetail = await isRetailClient();
  const res = await (isRetail ? retailApi : api).get<GetAllPromoResponse>(
    "/promotion",
    { params }
  );
  return res.data;
};

const getPromoDetail = async (id: string): Promise<GetPromoDetailResponse> => {
  const client_code = await getClientCode();
  const isRetail = await isRetailClient();
  const url = `/promotion/${id}?client_code=${client_code}`;
  const res = await (isRetail ? retailApi : api).get<GetPromoDetailResponse>(
    url
  );
  return res.data;
};

export const promoService: PromoService = {
  getAllPromo,
  getPromoDetail,
};
