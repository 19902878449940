import { FC, InputHTMLAttributes } from "react";
import "./styles.scss";
import { Icons } from "assets";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const backgroundImage = `url(${Icons.id})`;

export const InputPhone: FC<Props> = ({ name, ...props }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filteredValue = value?.replace(/[^0-9]/g, "");

    if (filteredValue !== value) {
      e.target.value = filteredValue;
    }

    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="input-wrapper">
      <div className="input-bg" style={{ backgroundImage }} />
      <div className="country-code">+62</div>
      <input
        id={name}
        type="text"
        {...props}
        style={{
          paddingLeft: "80px",
        }}
        inputMode="numeric"
        onChange={handleInputChange}
      />
    </div>
  );
};
