import { action, thunk, thunkOn } from 'easy-peasy'

import { FetchStatus } from 'types'

import { PromoItem, PromoModel, promoService } from '.'

export const promo: PromoModel = {
  promoStatus: 'running',
  paginator: {
    current_page: 1,
    next_page: 0,
    per_page: 10,
    previous_page: 0,
    total_items: 0,
    total_pages: 1,
  },
  promos: [],
  promoDetail: {} as PromoItem,
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setPromoStatus: action((state, payload) => {
    state.promoStatus = payload
  }),
  setPaginator: action((state, payload) => {
    state.paginator = payload
  }),
  setPromos: action((state, payload) => {
    state.promos = payload
  }),
  setPromoDetail: action((state, payload) => {
    state.promoDetail = payload
  }),
  addPromos: action((state, payload) => {
    state.promos = [...state.promos, ...payload]
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  fetchAllPromo: thunk((action, payload) => {
    action.setStatus(FetchStatus.LOADING)
    promoService
      .getAllPromo(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.setPromos(data)
        action.setStatus(FetchStatus.LOADED)
      })
      .catch((e) => {
        action.setError(e)
        action.setStatus(FetchStatus.ERROR)
      })
  }),

  fetchMorePromo: thunk((action, payload) => {
    action.setStatus(FetchStatus.FETCHING_MORE)
    promoService
      .getAllPromo(payload)
      .then(({ data, paginator }) => {
        action.setPaginator(paginator)
        action.addPromos(data)
        action.setStatus(FetchStatus.LOADED)
      })
      .catch(() => {
        action.setStatus(FetchStatus.FETCHING_MORE_ERROR)
        action.setStatus(FetchStatus.LOADED)
      })
  }),

  fetchPromoDetail: thunk((action, payload) => {
    action.setStatus(FetchStatus.LOADING)
    promoService
      .getPromoDetail(payload)
      .then(({ data }) => {
        action.setPromoDetail(data)
        action.setStatus(FetchStatus.LOADED)
      })
      .catch((e) => {
        action.setError(e)
        action.setStatus(FetchStatus.ERROR)
      })
  }),
}
