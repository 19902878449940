import { useState } from 'react'

import { api, isEmpty } from 'utils'
import { useStoreActions } from 'stores'

export interface EditProfileDto {
  profile_picture: File
  nik: string
  name: string
  mobile_phone: string
  email: string
  gender: string
  religion: string
  marital_status: string
  birth_date: string
  province: string
  city: string
  district: string
  address: string
  village: string
}

export interface UseEditProfile {
  isLoading: boolean
  error: string
  success: boolean
  submit: (dto: EditProfileDto) => Promise<void>
  setError: (value: string) => void
}

export const useEditProfile = (): UseEditProfile => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const { refreshProfile } = useStoreActions((action) => action.profile)

  const submit = async (dto: EditProfileDto): Promise<void> => {
    try {
      setIsLoading(true)
      const formData = new FormData()

      for (const key in dto) {
        formData.append(key, dto[key as keyof EditProfileDto])
      }
      
      await api.post('/customer',
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        transformRequest: formData => formData,
      })
      refreshProfile()
      setIsLoading(false)
      setSuccess(true)
    } catch (e: any) {
      console.log('ERROR', e)
      setError(e?.response?.data?.message || 'Mohon cek kembali jaringan Anda')
      setIsLoading(false)
      setSuccess(false)
    }
  }

  return {
    isLoading,
    error,
    success,
    setError,
    submit,
  }
}
