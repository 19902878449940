import { BookingRedeemModel, bookingRedeemModel } from './redeem_voucher'
import {
  RedeemProductVoucherItemsModel,
  redeemProductVoucherItemsModel
} from './voucher_items'
export * from './voucher_items/type'

export interface RedeemProductModel {
  voucherItems: RedeemProductVoucherItemsModel
  voucherRedeem: BookingRedeemModel
}

export const redeemProduct: RedeemProductModel = {
  voucherItems: redeemProductVoucherItemsModel,
  voucherRedeem: bookingRedeemModel
}