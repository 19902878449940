import { Alert, Button, Input, Topbar } from "components";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { QrScanner } from "@yudiel/react-qr-scanner";
import "./styles.scss";

export const ScanQr: FC = () => {
  const navigate = useNavigate();
  const [qrValue, setQrValue] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();

  const merchant = location?.state?.item;

  useEffect(() => {
    if (!merchant) {
      navigate("/addcard");
    }
  }, [merchant]);

  const handleScan = (value: string) => {
    setQrValue(value);
  };

  useEffect(() => {
    if (qrValue) {
      console.log("Qr value " + qrValue);
    }
  }, [qrValue]);

  return (
    <div className="Scan-Qr">
      <Topbar
        title="Place the QR code inside the area"
        onBack={() => navigate(-1)}
      />
      {!qrValue.length && (
        <div>
          <QrScanner
            onDecode={handleScan}
            onError={(error) => setError(error?.message)}
            scanDelay={1000}
          />
        </div>
      )}
      <div className="bottom-content">
        <div className="add-number">
          <span style={{ fontWeight: "bold" }}>
            Don’t have the QR code or membership yet?
          </span>
          <span>You can choose to add you number manually</span>
        </div>
        <Button
          label="Add Number Manually"
          onClick={() => navigate("/member/activate", { state: { merchant } })}
        />
        {merchant?.canRegister === 1 && (
          <div className="add-number">
            <div className="line">
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  width: "3rem",
                }}
              />
              <span>or</span>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  width: "3rem",
                }}
              />
            </div>
            <span
              style={{
                color: "#375AA8",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() =>
                navigate("/member/create", { state: { merchant } })
              }
            >
              Create Membership Now
            </span>
          </div>
        )}
      </div>
      {error && (
        <Alert title="Error" message={error} onClick={() => setError("")} />
      )}
    </div>
  );
};
