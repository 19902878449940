import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { useStoreActions, useStoreState } from "stores";

import { Topbar } from "components";

export const Promotion: FC = () => {
  const navigate = useNavigate();

  const { fetchAllPromo } = useStoreActions((action) => action.promo);
  const { promos } = useStoreState((state) => state.promo);
  const { client_code } = useStoreState((state) => state.membership);

  const getPromo = useCallback(() => {
    fetchAllPromo({
      page: 1,
      limit: 10,
      client_code: client_code,
      status: "running",
    });
  }, [fetchAllPromo, client_code]);

  useEffect(() => {
    getPromo();
  }, [client_code]);

  return (
    <div className="promotion-container">
      <Topbar title="Promotion" onBack={() => navigate(-1)} />
      <div className="content">
        {promos.map((item, index) => (
          <div
            key={index}
            className="card"
            onClick={() =>
              navigate(`/promotion/${item.id}`, { state: { data: item } })
            }
          >
            <img className="image" src={item.photos[0]} alt="promo-banner" />
            <div className="detail">
              <div className="title">{item.name}</div>
              <div className="date">
                <img
                  className="icon"
                  src={require("assets/icons/date.png")}
                  alt="icon"
                />
                <span>
                  {moment(item.period.start).format("DD MMM YYYY")} -{" "}
                  {moment(item.period.end).format("DD MMM YYYY")}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
