import { action, thunk } from 'easy-peasy'

import { FormStatus } from 'types'

import { BookingRedeemModel, bookingRedeemService } from '.'

export const bookingRedeemModel: BookingRedeemModel = {
  data: [],
  status: FormStatus.IDLE,
  error: '',

  // Actions
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),

  // Thunks
  submit: thunk(async (action, payload) => {
    try {
      action.setError('')
      action.setStatus(FormStatus.LOADING)
      action.setData([])

      const res = await bookingRedeemService.submitBookingRedeem(payload)
      if (res.status_code === 200 || res.http_code === 200) {
        action.setStatus(FormStatus.SUCCESS)
        action.setData(res.data)
      } else {
        action.setStatus(FormStatus.ERROR)
        action.setError(res?.message || 'Network Error')
      }
    } catch (e: any) {
      action.setStatus(FormStatus.ERROR)
      action.setError(e?.response?.data?.message || 'Network Error')
    }
  })
}
