import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { QRFunc } from "react-qrbtf";

import { Topbar, BottomSheet, Button, Alert, Input } from "components";

import { RedeemProduct, useStoreActions, useStoreState } from "stores";
import { getMemberAccessToken, isEmpty } from "utils";
import { FormStatus } from "types";

export const CatalogueDetail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tokenMember, setTokenMember] = useState<string | null>("");
  const getToken = async () => {
    const token = await getMemberAccessToken();
    setTokenMember(token);
  };
  const { client_code } = useStoreState((state) => state.membership);

  const { fetchRedeemProductDetail } = useStoreActions(
    (action) => action.redeemProduct.voucherItems
  );
  const { redeemProductDetail, status } = useStoreState(
    (state) => state.redeemProduct.voucherItems
  );

  const { fetchProfile } = useStoreActions((action) => action.profile);
  const { profile, status: statusProfile } = useStoreState(
    (state) => state.profile
  );

  const { submit, setError: setErrorRedeem, setStatus } = useStoreActions(
    (action) => action.redeemProduct.voucherRedeem
  );
  const { status: statusRedeem, error: errorRedeem } = useStoreState(
    (state) => state.redeemProduct.voucherRedeem
  );

  const [item, setItem] = useState<RedeemProduct>(redeemProductDetail);
  const [error, setError] = useState<string>("");

  let { id } = useParams();
  const data = location?.state?.data as RedeemProduct;
  let profilePhone = profile?.phone?.mobile
    ? profile?.phone?.mobile?.replace(/[^0-9]/g, "")
    : "";
  const [phone, setPhone] = useState<string>(
    data.name.toLowerCase().includes("pln")
      ? ""
      : `+${profilePhone}`
  );


  const redeemNow = () => {

    if (data?.source?.toLowerCase() === "internal") {
      const dto = {
        products: [{ id: data.id, qty: 1 }],
        source: data.source,
        type: data.type,
      };
      submit(dto);
    }
    if (data?.source?.toLowerCase() === "external") {
      const dto = {
        phone,
        product_code: data.code,
        qty: 1,
        point: data.point,
        source: data.source,
        type: data.type,
      };
      submit(dto);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (tokenMember) {
      fetchProfile({token: tokenMember, client_code});
    }
  }, [tokenMember]);

  useEffect(() => {
    setError(errorRedeem);
  }, [errorRedeem]);

  useEffect(() => {
    if (id) fetchRedeemProductDetail({ id, client_code });
  }, [fetchRedeemProductDetail, id]);

  useEffect(() => {
    setItem(data || redeemProductDetail);
  }, [redeemProductDetail, data]);

  const [open, setOpen] = useState<boolean>(false);

  const handleSuccess = () =>{
    setStatus(FormStatus.IDLE)
    navigate('/reward')
  }

  return (
    <div className="catalogue-detail-container">
      {!isEmpty(item) && (
        <>
          <Topbar title={item.name} onBack={() => navigate(-1)} />
          <img
            className="image"
            src={item.images[0]}
            alt="promo-banner"
            style={{ animation: "fadeIn 2s ease forwards" }}
          />
          <div
            className="detail"
            style={{ animation: "show 1.5s ease forwards" }}
          >
            <div className="label">Availability:</div>
            <div className="date">
              {moment(item.period.start).format("DD MMM YYYY")} -{" "}
              {moment(item.period.end).format("DD MMM YYYY")}
            </div>
            <div className="space" />
            <div className="note">{item.note}</div>
            <div className="space" />
            <div className="label">Terms and Conditions:</div>
            {item.terms?.split("\n").map((text: string, index: number) => (
              <div className="note" key={index}>
                {text}
              </div>
            ))}
            <div className="space" />
            <div className="label">How to use:</div>
            {item.instructions
              ?.split("\n")
              .map((text: string, index: number) => (
                <div className="note" key={index}>
                  {text}
                </div>
              ))}
          </div>
          <div className="bottom-action">
            <Button label="Redeem Now" onClick={() => setOpen(true)} />
            <BottomSheet isOpen={open} onChange={setOpen}>
              <div className="bottom-sheet-content">
                <div className="title">{item.name}</div>
                <div className="point">{item.point} Points</div>
                {data.type === "ppob" && (
                  <div style={{ marginTop: 20 }}>
                    <label>
                      {data.name.toLowerCase().includes("pln")
                        ? "No. Meter/ID PLN"
                        : "Phone Number"}
                    </label>
                    {data.name
                      .toLowerCase()
                      .includes("pln") ? (
                      <Input.Text
                        name={
                          data.name
                            .toLowerCase()
                            .includes("pln")
                            ? "No. Meter/ID PLN"
                            : "Phone Number"
                        }
                        value={phone?.replace("+62", "").replace(/[^0-9]/, "")}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    ) : (
                      <Input.Phone
                        name={
                          data.name
                            .toLowerCase()
                            .includes("pln")
                            ? "No. Meter/ID PLN"
                            : "Phone Number"
                        }
                        value={phone
                          ?.replace("+62", "")
                          .replace(/^0|[^0-9]/, "")}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    )}
                  </div>
                )}
                <div className="footer">
                  <div className="my-point">
                    <div className="label">My Points</div>
                    <div className="point">
                      {profile?.point?.redeem | 0} Points
                    </div>
                  </div>
                  <Button
                    label="Redeem"
                    isLoading={statusRedeem === FormStatus.LOADING}
                    onClick={redeemNow}
                    disabled={profile?.point?.redeem < item?.point}
                  />
                </div>
              </div>
            </BottomSheet>
          </div>
        </>
      )}
      {!isEmpty(error) && (
        <Alert
          title="Redeem Failed"
          message={error}
          onClick={() => setErrorRedeem("")}
        />
      )}
      {statusRedeem === FormStatus.SUCCESS && (
        <Alert
          title="Redeem Success"
          message=""
          onClick={handleSuccess}
        />
      )}
    </div>
  );
};
