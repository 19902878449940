import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "stores";

import { Loader, Topbar } from "components";

import { FetchStatus } from "types";
import { Images } from "assets";

export const Store: FC = () => {
  const navigate = useNavigate();

  const { fetchAllOutlet } = useStoreActions((action) => action.outlet);
  const { outlets, status } = useStoreState((state) => state.outlet);
  const { client_code } = useStoreState((state) => state.membership);

  const getOutlet = useCallback(() => {
    fetchAllOutlet({
      page: 1,
      limit: 100,
      client_code,
    });
  }, [fetchAllOutlet, client_code]);

  useEffect(() => {
    getOutlet();
  }, [getOutlet]);

  return (
    <div className="store-container">
      <Topbar title="Store" onBack={() => navigate(-1)} />
      {status === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <div className="content">
          {outlets.map((item, index) => (
            <div className="card" key={index}>
              <img
                className="image"
                src={item.image || Images.loyalid}
                alt="store-banner"
              />
              <div className="detail">
                <div className="title">{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
