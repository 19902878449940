import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "stores";
import { FetchStatus, Membership } from "types";
import { formatCurrency, isEmpty } from "utils";
import { Loader } from "components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Scrollbar } from "swiper/modules";

import {
  useClientInfo,
  useClientLink,
  useLoginBarcode,
  useMemberLevel,
  useMemberProfile,
} from "./hooks";

import { Icons } from "assets";

const FeatureMerchantList = [
  {
    title: "Catalogue",
    icon: Icons.catalogue,
    navigate: "/catalogue",
  },
  {
    title: "Store",
    icon: Icons.store,
    navigate: "/store",
  },
  {
    title: "Profile",
    icon: Icons.profile,
    navigate: "/profile/edit",
  },
  {
    title: "Promotion",
    icon: Icons.promotion,
    navigate: "/promotion",
  },
  {
    title: "Event",
    icon: Icons.calendar,
    navigate: "/event",
  },
  {
    title: "Convert Point",
    icon: Icons.coin,
    navigate: "/convert",
  },
  {
    title: "Instagram",
    icon: Icons.instagram,
  },
];

export interface Feature {
  id: number;
  clientsCode: string;
  name: string;
  icon: string;
  type: string;
  featureName: string;
  webviewLink: string;
  active: number;
}

export const Home = () => {
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const { fetchAllPromo } = useStoreActions((action) => action.promo);
  const { promos } = useStoreState((state) => state.promo);

  const { fetchRedeemProduct } = useStoreActions(
    (action) => action.redeemProduct.voucherItems
  );
  const { redeemProduct, status: redeemProductStatus } = useStoreState(
    (state) => state.redeemProduct.voucherItems
  );

  const navigate = useNavigate();

  const location = useLocation();
  const member: Membership = location?.state?.data;

  useEffect(() => {
    if (!member) navigate("/membership");
  }, [member, navigate]);

  const { submit, data } = useLoginBarcode();

  const { data: profile, status: profileStatus } = useMemberProfile(
    member?.client?.code,
    data.api_token,
    member?.client_type
  );

  const level = useMemberLevel(
    member?.client?.code,
    data.api_token,
    data.barcode,
    member?.client_type.toLowerCase()
  );

  const clientLink = useClientLink(member?.client?.code);
  const clientInfo = useClientInfo(member?.client?.code);
  const cardLevel = level?.current_level
    ? level.current_level[0]?.level_name
    : null;

  useEffect(() => {
    if (isAuthenticated && member) {
      submit(
        member.barcode,
        member.client.code,
        member.client_type.toLowerCase(),
        member.phone
      );
      fetchAllPromo({
        page: 1,
        limit: 10,
        client_code: member.client.code,
        status: "running",
      });
      fetchRedeemProduct({ client_code: member.client.code });
    }
  }, [isAuthenticated, member]);

  const clickFeature = (item: Feature) => {
    if (item.webviewLink) {
      window.open(item.webviewLink, "_blank");
    } else {
      const feature = FeatureMerchantList.find(
        (list) => list.title === item.name
      );
      if (feature && feature.navigate) {
        navigate(feature.navigate);
      } else {
        navigate("/not-found");
      }
    }
  };

  return (
    <div className="home">
      {!member?.client?.code ||
      redeemProductStatus === FetchStatus.LOADING ||
      profileStatus === FetchStatus.LOADING ? (
        <Loader />
      ) : (
        <>
          <div className="header">
            <div className="title">
              <div className="back" onClick={() => navigate(-1)}>
                <img
                  className="icon"
                  src={require("assets/icons/arrow-left.png")}
                />
              </div>
              <div className="center">{member?.client?.name}</div>
            </div>
            <div className="feature">
              {/* <div onClick={() => navigate("/snap")}>
                <img
                  className="icon"
                  src={require("assets/images/camera.png")}
                  alt="icon"
                />
                <div className="label">Snap</div>
              </div> */}
              <div
                onClick={() =>
                  navigate("/history", {
                    state: { data: member, api_token: data.api_token },
                  })
                }
              >
                <img
                  className="icon"
                  src={require("assets/images/history.png")}
                  alt="icon"
                />
                <div className="label">History</div>
              </div>
              <div
                onClick={() =>
                  navigate("/reward", {
                    state: { data: member, api_token: data.api_token },
                  })
                }
              >
                <img
                  className="icon"
                  src={require("assets/images/gift.png")}
                  alt="icon"
                />
                <div className="label">My Reward</div>
              </div>
            </div>
            <div className="point-expense">
              <div className="card">
                <div className="content">
                  <div className="icon">
                    <img
                      src={require("assets/images/coins.png")}
                      style={{
                        height: 20,
                        filter: "brightness(0)",
                        opacity: 0.6,
                      }}
                      alt="icon"
                    />
                  </div>
                  <div className="amount">
                    {formatCurrency(profile?.point?.redeem || 0)}
                  </div>
                  <div className="label">Redeem Points</div>
                </div>
              </div>
              <div className="card">
                <div className="content">
                  <div className="icon">
                    <img
                      src={require("assets/images/coupon.png")}
                      style={{
                        height: 20,
                        filter: "brightness(0)",
                        opacity: 0.6,
                      }}
                      alt="icon"
                    />
                  </div>
                  <div className="amount">
                    {formatCurrency(profile?.point?.lucky_draw || 0)}
                  </div>
                  <div className="label">Lucky Draw</div>
                </div>
              </div>
              <div className="card">
                <div className="content">
                  <div className="icon">
                    <img
                      src={require("assets/images/xp.png")}
                      style={{
                        height: 20,
                        filter: "brightness(0)",
                        opacity: 0.6,
                      }}
                      alt="icon"
                    />
                  </div>
                  <div className="amount">
                    {formatCurrency(Math.round(level?.total_expense || 0))}
                  </div>
                  <div className="label">XP</div>
                </div>
              </div>
            </div>
            <div className="member-card">
              <div className="content">
                <div
                  className="icon"
                  style={{
                    backgroundImage: `url(${member?.client?.logo})`,
                  }}
                />
                <div className="user">
                  <div className="name">{profile?.name}</div>
                  <div className="badge">
                    <div className="content">
                      <img
                        className="badge-icon"
                        src={require("assets/images/badge.png")}
                        alt="icon"
                      />
                      <span>{cardLevel || profile?.card_type?.name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="feature-merchant">
              {clientLink.map((item, index) => {
                return (
                  <div
                    key={`${item.name}+${index}`}
                    onClick={() => clickFeature(item)}
                    className="content"
                  >
                    <img
                      src={
                        item.icon
                          ? item.icon
                          : FeatureMerchantList?.find(
                              (list) => list.title === item.name
                            )?.icon
                      }
                      className="icon"
                    />
                    <span>{item.name}</span>
                  </div>
                );
              })}
            </div>
            {!isEmpty(promos) && (
              <div className="promotion">
                <div className="top">
                  <div className="title">Promotion</div>
                  <div
                    className="see-all"
                    onClick={() => navigate("/promotion")}
                  >
                    <span>See all</span>
                    <img
                      className="arrow"
                      src={require("assets/icons/chevron-right.png")}
                      alt="icon"
                    />
                  </div>
                </div>
                <Swiper
                  className="mySwiper"
                  modules={[Autoplay, Scrollbar]}
                  spaceBetween={0}
                  slidesPerView={1}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  scrollbar={{
                    hide: false,
                  }}
                  loop
                >
                  {promos.map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        className="banner"
                        src={item.photos[0]}
                        alt="promo-banner"
                        onClick={() =>
                          navigate(`/promotion/${item.id}`, {
                            state: { data: item },
                          })
                        }
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}
            <div className="popular-catalogue">
              <div className="top">
                <div className="title">Popular Catalogue</div>
                <div className="see-all" onClick={() => navigate("/catalogue")}>
                  <span>See all</span>
                  <img
                    className="arrow"
                    src={require("assets/icons/chevron-right.png")}
                    alt="icon"
                  />
                </div>
              </div>
              <div className="catalogue">
                {redeemProduct.map((item, index) => (
                  <div
                    className="card"
                    key={index}
                    onClick={() =>
                      navigate(`/catalogue/${item.id}`, {
                        state: { data: item },
                      })
                    }
                  >
                    <div
                      className="image"
                      style={{
                        width: "8rem",
                        height: "8rem",
                        borderRadius: "1rem 1rem 0 0",
                        backgroundImage: `url(${item.images})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        borderBottom: "1px solid #ddd",
                      }}
                    />
                    <div className="detail">
                      <div className="name">{item.name}</div>
                      <div className="point">{item.point} Points</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
