import { action, thunk, thunkOn } from 'easy-peasy'

import { AuthStatus, FetchStatus } from 'types'
import { isEmpty } from 'utils'

import { Profile, ProfileModel, ProfileService } from '.'

export const profile: ProfileModel = {
  profile: {} as Profile,
  status: FetchStatus.LOADING,
  error: {},

  // Actions
  setProfile: action((state, payload) => {
    if (isEmpty(state.profile)) {
      console.debug('Sending one signal tag', payload?.email)
    }
    state.profile = payload
  }),
  setStatus: action((state, payload) => {
    state.status = payload
  }),
  setError: action((state, payload) => {
    state.error = payload
  }),
  clearState: action((state) => {
    state.profile = {} as Profile
    state.status = FetchStatus.LOADING
    state.error = {}
  }),

  // Thunks
  fetchProfile: thunk(async (action, payload) => {
    try {
      action.setStatus(FetchStatus.LOADING)
      const res = await ProfileService.fetchProfile(payload)
      action.setProfile(res.data)
      action.setStatus(FetchStatus.LOADED)
    } catch (e) {
      action.clearState()
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      action.setError({})
    }
  }),
  refreshProfile: thunk(async (action) => {
    try {
      action.setStatus(FetchStatus.REFRESHING)
      const res = await ProfileService.fetchProfile()
      action.setProfile(res.data)
    } catch (e) {
      action.clearState()
      action.setError(e)
      action.setStatus(FetchStatus.ERROR)
    } finally {
      action.setError({})
    }
  }),

  // Thunks On
  onLoggedOut: thunkOn(
    (_, storeAction) => storeAction.auth.setAuthStatus,
    (action, target) => {
      if (target.payload === AuthStatus.UNAUTHENTICATED) {
        action.clearState()
      }
    }
  )
}