import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { RedeemVoucher, useStoreActions, useStoreState } from "stores";
import { FetchStatus, Membership } from "types";
import { BottomSheet, Loader, Topbar } from "components";
import { QRFunc } from "react-qrbtf";
import { getMemberAccessToken } from "utils";

export const Reward = () => {
  const navigate = useNavigate();
  const [type, setType] = useState<string>("not-used");
  const [showCode, setShowCode] = useState<boolean>(false);
  const [data, setData] = useState<RedeemVoucher>();
  const location = useLocation();
  const [memberToken, setMemberToken] = useState<string>(
    location?.state?.api_token
  );
  const { fetchRedeemVouchersUnused, fetchRedeemVouchersUsed } =
    useStoreActions((action) => action.redeemVoucher);
  const { unused, used, statusUnused, statusUsed } = useStoreState(
    (state) => state.redeemVoucher
  );

  const member: Membership = location?.state?.data;
  const { membership } = useStoreState((state) => state.membership);

  useEffect(() => {
    if (!memberToken) {
      const fetchToken = async () => {
        const token = await getMemberAccessToken();
        if (token) {
          setMemberToken(token);
        }
      };

      fetchToken();
    }
  }, [memberToken]);

  useEffect(() => {
    if (membership && memberToken) {
      fetchRedeemVouchersUnused({
        paginator: {
          limit: 100,
          page: 1,
        },
        status: "not-used",
        code_client: membership.client.code,
        access_token: memberToken,
      });
      fetchRedeemVouchersUsed({
        paginator: {
          limit: 100,
          page: 1,
        },
        status: "used",
        code_client: membership.client.code,
        access_token: memberToken,
      });
    }
  }, [membership, memberToken]);

  const loading =
    statusUnused === FetchStatus.LOADING || statusUsed === FetchStatus.LOADING;

  return (
    <div className="reward">
      <div className="navigation">
        <Topbar title="My Reward" onBack={() => navigate(-1)} />
        <div className="category">
          <div
            className="tab"
            style={{
              backgroundColor: type === "not-used" ? "#2e4a8a" : "#fff",
              color: type === "not-used" ? "#fff" : "#000",
            }}
            onClick={() => setType("not-used")}
          >
            Available Voucher
          </div>
          <div
            className="tab"
            style={{
              backgroundColor: type === "used" ? "#2e4a8a" : "#fff",
              color: type === "used" ? "#fff" : "#000",
            }}
            onClick={() => setType("used")}
          >
            Used
          </div>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ overflow: "auto" }}>
          {type === "not-used" ? (
            <div className="page">
              {unused.map((item, index) => (
                <div className="card" key={index}>
                  <div className="content">
                    <div className="detail">
                      <img
                        className="image"
                        src={item.product.images[0]}
                        alt="image"
                      />
                      <div>
                        <div className="product">{item.product.name}</div>
                        <div className="valid-until">Valid until:</div>
                        <div className="valid-date">
                          {moment(item.expired_at).format("DD  MMM YYYY")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCode(true);
                        setData(item);
                      }}
                    >
                      Show My Code
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="page">
              {used.map((item, index) => (
                <div className="card" key={index}>
                  <div className="content">
                    <div className="detail">
                      <img
                        className="image"
                        src={item.product.images[0]}
                        alt="image"
                      />
                      <div>
                        <div className="product">{item.product.name}</div>
                        <div className="valid-until">Valid until:</div>
                        <div className="valid-date">
                          {moment(item.expired_at).format("DD  MMM YYYY")}
                        </div>
                      </div>
                    </div>
                    <div
                      className="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowCode(true);
                        setData(item);
                      }}
                    >
                      Show Detail
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <BottomSheet isOpen={showCode} onChange={setShowCode}>
        <div className="voucher-profile">
          <div className="title">{data?.product?.name}</div>
          <div className="info">Show this code to be scanned by the staff</div>
          <div className="qr-box">
            <QRFunc
              className="qr-code"
              value={data?.serial_no}
              type="round"
              posType="round"
            />
          </div>
          <div className="code">{data?.serial_no}</div>
        </div>
      </BottomSheet>
    </div>
  );
};
