import {
  api,
  getClientCode,
  isRetailClient,
  retailApi,
} from "utils";

import {
  GetAllEventResponse,
  GetAllEventParams,
  EventService,
  GetEventDetailResponse,
} from ".";

const getAllEvent = async (
  params: GetAllEventParams
): Promise<GetAllEventResponse> => {
  try {
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).get<GetAllEventResponse>(
      "/event",
      { params }
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

const getEventDetail = async (id: string): Promise<GetEventDetailResponse> => {
  try {
    const client_code = await getClientCode();
    const isRetail = await isRetailClient();
    const res = await (isRetail ? retailApi : api).get<GetEventDetailResponse>(
      `/event/${id}?client_code=${client_code}`
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const eventService: EventService = {
  getAllEvent,
  getEventDetail,
};
